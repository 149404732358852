import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import {
    getSupplierData,
    addSupplierData,
    deleteSupplierData,
    updateSupplierData,
} from "../../../../Redux/API/api_SupplierMaster";
import {
    tableOptions,
    tableHeaderStyleSupplier,
} from "../../../Components/CommonComponents/common_components";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import { tableIcons, userEmail } from "../../../../Utilities/utility";
import "../AdminMasterTabs.scss";
export default function SupplierMaster() {
    const userdetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const [isEditableSupplierCode, setIsEditableSupplierCode] =
        useState("always");
    const [isEditableSupplierName, setIsEditableSupplierName] =
        useState("always");

    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    function isSupplierExists(value, type) {
        let obj = {};
        if (type === "Code") {
            obj = tableData.find((c) => c.supplierCode === value);
            return obj ? true : false;
        } else if (type === "Name") {
            obj = tableData.find((c) => c.supplierName === value);
            return obj ? true : false;
        } else {
            obj = tableData.find((c) => c.primaryEmailId === value);
            return obj ? true : false;
        }
    }
    function isSupplierExists(value, type, id) {
        let obj = {};
        if (type === "Code") {
            obj = tableData.find((c) => c.supplierCode === value);
            return obj && obj.supplierDetailsId !== id ? true : false;
        } else if (type === "Name") {
            obj = tableData.find((c) => c.supplierName === value);
            return obj && obj.supplierDetailsId !== id ? true : false;
        } else {
            obj = tableData.find((c) => c.primaryEmailId === value);
            return obj && obj.supplierDetailsId !== id ? true : false;
        }
    }
    function isValidEmail(email) {
        const re =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return re.test(String(email).toLowerCase());
    }
    function isValidSupplierNameField(name) {
        const re = /^[a-zA-Z ]*$/;
        return re.test(name);
    }
    function isValidSupplierCodeField(code) {
        const re = /^[0-9]*$/;
        return re.test(code);
    }
    function validatePrimaryEmail(value, id) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isValidEmail(value)
                ? { isValid: false, helperText: "Invalid Email" }
                : (!id && isSupplierExists(value, "Email")) ||
                    (id && isSupplierExists(value, "Email", id))
                    ? { isValid: false, helperText: "Already exists" }
                    : { isValid: true, helperText: "" };
    }
    function validateSecondaryEmail(value) {
        return value && !isValidEmail(value)
            ? { isValid: false, helperText: "Invalid Email" }
            : { isValid: true, helperText: "" };
    }
    function validateSupplierName(value, id) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isValidSupplierNameField(value)
                ? { isValid: false, helperText: "Only Alphabets" }
                : (!id && isSupplierExists(value, "Name")) ||
                    (id && isSupplierExists(value, "Name", id))
                    ? { isValid: false, helperText: "Already exists" }
                    : { isValid: true, helperText: "" };
    }
    function validateSupplierCode(value, id) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isValidSupplierCodeField(value)
                ? { isValid: false, helperText: "Only integers" }
                : (!id && isSupplierExists(value, "Code")) ||
                    (id && isSupplierExists(value, "Code", id))
                    ? { isValid: false, helperText: "Already exists" }
                    : { isValid: true, helperText: "" };
    }

    var columns = [
        {
            title: "SupplierDetailsId",
            field: "supplierDetailsId",
            hidden: true,
        },
        {
            title: "Supplier Code",
            field: "supplierCode",
            cellStyle: {
                textAlign: "center",
            },
            headerStyle: tableHeaderStyleSupplier,
            validate: (rowData) =>
                validateSupplierCode(rowData.supplierCode, rowData.supplierDetailsId),
        },
        {
            title: "Supplier Name",
            field: "supplierName",
            validate: (rowData) =>
                validateSupplierName(rowData.supplierName, rowData.supplierDetailsId),
        },
        {
            title: "Primary Email",
            field: "primaryEmailId",
            validate: (rowData) =>
                validatePrimaryEmail(rowData.primaryEmailId, rowData.supplierDetailsId),
        },
        {
            title: "Secondary Email",
            field: "secondaryEmailId",
            validate: (rowData) => validateSecondaryEmail(rowData.secondaryEmailId),
        },
    ];
    const [tableData, setTableData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const siteToken = useSelector((state) => state.saveTokenReducer);

    function SupplierFieldReadOnly(data) {
        data.map((item) => {
            if (item.isReadOnlySupplier) {
                setIsEditableSupplierCode("never");
                setIsEditableSupplierName("never");
            } else {
                setIsEditableSupplierCode("always");
                setIsEditableSupplierName("always");
            }
            return item;
        });
    }
    function getTableData() {
        setIsLoader(true);
        getSupplierData(
            siteToken,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            azureToken.token
        )
            .then((response) => {
                setTableData(response);
                SupplierFieldReadOnly(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    const enterKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", enterKeyDown);

        getTableData();

        return () => {
            document.removeEventListener("keydown", enterKeyDown);
        };
    }, [userdetails]);

    const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.loggedinUserId = userdetails.userId;
        updateSupplierData(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            newTableData

        )
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowAdd = (newTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.loggedinUserId = userdetails.userId;
        addSupplierData(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            newTableData
        )
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowDelete = (oldTableData, resolve, reject) => {
        setIsLoader(true);
        oldTableData.loggedinUserId = userdetails.userId;
        deleteSupplierData(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            oldTableData
        )
            .then((response) => {
                if (response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    function getDisableTooltip(value, type) {
        return value === true
            ? "Supplier already in use"
            : type === "Edit"
                ? "Edit"
                : "Delete";
    }
    return (
        <div>
            <div className="App">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <MaterialTable
                            title=""
                            columns={columns}
                            data={tableData}
                            icons={tableIcons}
                            isLoading={isLoader}
                            options={tableOptions}
                            editable={{
                                isDeletable: (rowData) => !rowData.isReadOnlySupplier,
                                deleteTooltip: (rowData) =>
                                    getDisableTooltip(rowData.isReadOnlySupplier, "Delete"),
                                onRowUpdate: (newTableData, oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowUpdate(
                                            newTableData,
                                            oldTableData,
                                            resolve,
                                            reject
                                        );
                                    }),
                                onRowAdd: (newTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowAdd(newTableData, resolve, reject);
                                    }),
                                onRowDelete: (oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowDelete(oldTableData, resolve, reject);
                                    }),
                            }}
                        />
                    </Grid>
                </Grid>
                <>
                    <SnackbarCustom
                        open={open}
                        message={snackMessage}
                        alertType={alertType}
                        handleClose={handleClose}
                    />
                </>
            </div>
        </div>
    );
}
