import { baseApiURL } from "../../Utilities/utility";
import axios from "axios";

export function getGRNReport(
    siteToken,
    userEmail,
    gid,
    roleName,
    userId,
    roleId,
    azureToken
) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
    };
    return fetch(
        baseApiURL + "/Report/GetGRNReport?userId=" + encodeURIComponent(userId),
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function prodLiveWip(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data

) {
    const options = {
        responseType: "arraybuffer",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
    };
    return axios
        .post(baseApiURL + "/Report/GetLiveWIPReport?userId=" + encodeURIComponent(userId), data, options)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function PendingWithSupplier(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data

) {
    const options = {
        responseType: "arraybuffer",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
    };
    return axios
        .post(baseApiURL + "/Report/GetSupplierReport?userId=" + encodeURIComponent(userId), data, options)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getDailyStageWiseReport(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data

) {
    const options = {
        responseType: "arraybuffer",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
    };
    return axios
        .post(baseApiURL + "/Report/GetStageWiseDailyReport?userId=" + encodeURIComponent(userId), data, options)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function LeadTimeCurrentStage(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data    
) {
    const options = {
        responseType: "arraybuffer",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
    };
    return axios
        .post(baseApiURL + "/Report/LeadTimeCurrentStage?userId=" + encodeURIComponent(userId), data, options)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function BDC101CustomReport(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data
    
) {
    const options = {
        responseType: "arraybuffer",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
    };
    return axios
        .post(baseApiURL + "/Report/BDC101CustomReport?userId=" + encodeURIComponent(userId), data, options)
        .then((response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deletedProdOrdersReport(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data
    
) {
    const options = {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + siteToken.token,
        UserEmail: userEmail,
        RoleName: roleName,
        Gid: gid,
        Roleid: roleId,
        AzureToken: azureToken,
      },
      // body: JSON.stringify(data),
    };
    return axios
      .post(
        baseApiURL +
          "/Report/GetDeletedPOSReport?userId=" +
          encodeURIComponent(userId),
        data,
        options
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
}
export function prodTotalLeadTime(
    siteToken,
    data,
    userEmail,
    gid,
    roleName,
    roleId,
    azureToken
) {
    const options = {
        responseType: "arraybuffer",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
    };
    return axios
        .post(
            baseApiURL + "/Report/ProdTotalLeadTimeReportWithFilter",
            data,
            options
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function StageWiseLeadTimeReport(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data
    
    
) {
    const options = {
        responseType: "arraybuffer",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
    };
    return axios
        .post(baseApiURL + "/Report/StageWiseLeadTimeDateReport?userId=" + encodeURIComponent(userId), data, options)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}


