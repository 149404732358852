import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { getStageDetails } from "../../../Redux/API/api_Stages";
import { useSelector } from "react-redux";
import { baseApiURL } from "../../../Utilities/utility";
import { tableOptions } from "../../Components/CommonComponents/common_components";
import { Button, Grid, Switch, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";

export default function Packing() {
  const userdetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const [packingPO, setpackingPO] = useState([]);
    const [packingData, setpackingData] = useState([]);
    const [tableLoader, setTableLoader] = useState(true);
    const apiURL = baseApiURL + "/ProdOrder/GetPackingPos";
    const siteToken = useSelector((state) => state.saveTokenReducer);
    let history = useHistory();

    const [isSmallPO, setIsSmallPO] = useState(false);

    const columns = [
      {
        title: "Scan PO",
        field: "scanPO",
        render: (rowData) => scanProdOrder(rowData),
      },
      {
        title: "Production Order",
        field: "productionOrderNumber",
      },
      {
        title: "ProductId",
        field: "productId",
        hidden: true,
      },
      {
        title: "Product",
        field: "product",
      },
      { title: "Pole Count", field: "poleCount" },
      { title: "QR Paper Count", field: "paperCount" },
    ];

    function scanProdOrder(data) {
      return (
        <Button
          className="pt-button--tertiary"
          onClick={() => {
            history.push({
              pathname: "/stagePackingScanner",
              state: {
                rowData: data,
              },
            });
          }}>
          Scan PO
        </Button>
      );
    }

    const toggleChange = (e) => {
      setIsSmallPO(e.target.checked);
      let filterdPOs = [];
      if (e.target.checked) {
        filterdPOs = packingPO.filter((x) => x.isSmallPO === e.target.checked);
        setpackingData(filterdPOs);
      } else {
        filterdPOs = packingPO;
      }
      setpackingData(filterdPOs);
    };
    function getTableData() {
      setTableLoader(true);
      getStageDetails(
        apiURL,
        siteToken,
        UID.id,
        userdetails.userEmail,
        userdetails.gid,
        userdetails.roleName,
        userdetails.roleId,
        azureToken.token
      )
        .then((response) => {
          setpackingPO(response.objResult ? response.objResult : []);
          setpackingData(response.objResult ? response.objResult : []);
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log("Error: " + error, "error");
        });
    }

  useEffect(() => {
    getTableData();
  }, [userdetails]);

  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography component="div">
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>Regular PO</Grid>
            <Grid item>
              <Switch checked={isSmallPO} onChange={toggleChange} />
            </Grid>
            <Grid item>Small PO</Grid>
          </Grid>
        </Typography>
      </div>
      <div>
        <h3>Packing</h3>
        <MaterialTable
          title="Packing"
          columns={columns}
          data={packingData}
          isLoading={tableLoader}
          options={tableOptions}
        />
      </div>
      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </>
  );
}
