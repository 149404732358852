import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@material-ui/core";
import { deleteProductionOrder, getPoListData } from "../../Redux/API/api_bulkUploadPOList";
import Loading from "../Components/Loading/Loading";
import { tableCellStyleMLFB, tableOptions } from "../Components/CommonComponents/common_components";

const useStyles = makeStyles((theme) => ({
  tableMargin: {
    marginTop: "20px",
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function POListTable() {
  const [tableData, setTableData] = useState("");
  const siteToken = useSelector((state) => state.saveTokenReducer);
   const azureToken = useSelector((state) => state.saveAzureTokenReducer);
   const userDetails = useSelector(
     (state) => state.storeUserDetailsReducer.userData
   );
   const UID = useSelector((state) => state.saveUserIdReducer);
   const uploadResponse = useSelector(
     (state) => state.bulkUploadSuccessReducer
   );
   const classes = useStyles();
   const [rowData, setRowData] = useState("");

   const [open, setOpen] = React.useState(false);
   const [snackMessage, setSnackMessage] = React.useState("");
   const [state, setState] = React.useState({
     vertical: "top",
     horizontal: "center",
   });
   const { vertical, horizontal } = state;

   const handleSnackClose = (event, reason) => {
     setOpen(false);
   };

   const handleSnackOpen = (text) => {
     setSnackMessage(text);
     setOpen(true);
     setState({
       vertical: "bottom",
       horizontal: "center",
     });
   };

   function getTableData() {
     getPoListData(
       siteToken,
       UID.id,
       userDetails.userEmail,
       userDetails.gid,
       userDetails.roleName,
       userDetails.roleId,
       azureToken.token
     ).then((response) => {
       setTableData(response);
     });
   }

   useEffect(() => {
     getTableData();
   }, [uploadResponse]);

   function getDeleteButton(data) {
     return (
       <Button
         className="pt-button--delete"
         disabled={
           userDetails && userDetails.roleId !== 1 && userDetails.roleId !== 3
         }
         onClick={() => {
           handleClickOpen();
           setRowData(data);
         }}>
         Delete
       </Button>
     );
   }

   const columns = [
     {
       title: "Production Order Id",
       field: "productionOrderDetailsId",
       hidden: true,
     },
     {
       title: "Delivery Dates",
       field: "deliveryDate",
     },
     {
       title: "Production Order",
       field: "productionOrderNumber",
     },
     {
       title: "FERT Material Code",
       field: "fertmaterialCode",
     },
     {
       title: "MLFB",
       field: "mlfb",
       cellStyle: tableCellStyleMLFB,
       type: "numeric",
     },
     {
       title: "Qty. in Breaker",
       field: "qtyInBreaker",
     },
     {
       title: "Purchase Order",
       field: "purchaseOrderNumber",
     },
     {
       title: "Item",
       field: "item",
       type: "numeric",
     },
     {
       title: "PO Date",
       field: "poDate",
     },
     {
       title: "Subcontracting Material Number",
       field: "materialNumber",
       type: "numeric",
     },
     {
       title: "Remark (Siemens)",
       field: "remarkFromSiemens",
     },
     {
       title: "Type",
       field: "type",
     },
     {
       title: "Rating",
       field: "rating",
     },
     {
       title: "Qty Poles",
       field: "qtyInPole",
     },
     {
       title: "Supplier Code",
       field: "supplierCode",
     },
     {
       title: "Supplier Name",
       field: "supplierName",
     },
     {
       title: "Delete PO",
       field: "deletePO",

       render: (rowData) => getDeleteButton(rowData),
     },
   ];

   const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
   const handleClickOpen = () => {
     setOpenDeleteDialog(true);
   };

   const handleClose = () => {
     setOpenDeleteDialog(false);
   };
   const dialogDeleteClick = () => {
     setOpenDeleteDialog(false);
     deletePoData(rowData.productionOrderDetailsId);
   };

   function deletePoData(id) {
     deleteProductionOrder(
         siteToken,
         azureToken.token,
         UID.id,
        
       userDetails.userId,
       userDetails.userEmail,
       userDetails.gid,
       userDetails.roleName,
         userDetails.roleId,
         id
      
     )
       .then((response) => {
         if (response.response) {
           handleSnackOpen(response.responseMsg);
           getTableData();
         } else {
           handleSnackOpen(response.responseMsg);
           getTableData();
         }
       })
       .catch((error) => {
         handleSnackOpen("Exception In Delete PO : " + error);
       });
   }

  return (
    <>
      <h3
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        Production Order List
      </h3>
      <div className={classes.root}>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleSnackClose}
          message={snackMessage}
          autoHideDuration={2000}
          key={vertical + horizontal}
          role="alert"
        />
      </div>

      <div>
        <Dialog
          open={openDeleteDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete PO"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure want to delete {rowData.productionOrderNumber}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              className="pt-button--secondary"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={dialogDeleteClick}
              className="pt-button--reject"
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div className={classes.tableMargin}>
        {tableData !== "" ? (
          <MaterialTable
            columns={columns}
            data={tableData}
            options={tableOptions}
          />
        ) : (
          <Loading />
        )}
      </div>
    </>
  );
}
