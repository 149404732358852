import BulkUpload from "./BulkUpload";
import POList from "./POList";
import "./BulkUpload.scss";

export default function BulkUploadAndPOList() {
  return (
    <div className="bulk-upload-polist-block">
      <BulkUpload />
      <POList />
    </div>
  );
}
