import { baseApiURL } from "../../Utilities/utility";

export function grnScanner(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data
 
 
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
    body: JSON.stringify(data),
  };
    return fetch(baseApiURL + "/ProdOrder/GRNSScanPO?userId=" + encodeURIComponent(userId), options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function bufferLocation1Scanner(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data 
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      // Authorization: "Bearer " + siteToken.token,
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
    body: JSON.stringify(data),
  };
    return fetch(baseApiURL + "/ProdOrder/BufferLocation1ScanPO?userId=" + encodeURIComponent(userId), options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function testingScanner(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data 
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
    body: JSON.stringify(data),
  };
    return fetch(baseApiURL + "/ProdOrder/TestingScanPO?userId=" + encodeURIComponent(userId), options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function bufferLocation2Scanner(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data  
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
    body: JSON.stringify(data),
  };
    return fetch(baseApiURL + "/ProdOrder/BufferLocation2ScanPO?userId=" + encodeURIComponent(userId), options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function bdcScanner(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data,
  
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
    body: JSON.stringify(data),
  };
    return fetch(baseApiURL + "/ProdOrder/BDCScanPO?userId=" + encodeURIComponent(userId), options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function packingScanner(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data 
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
    body: JSON.stringify(data),
  };
    return fetch(baseApiURL + "/ProdOrder/PackingScanPO?userId=" + encodeURIComponent(userId), options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function pdScanner(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data 
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
    body: JSON.stringify(data),
  };
    return fetch(baseApiURL + "/ProdOrder/PDScanPO?userId=" + encodeURIComponent(userId), options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function postTestingScanner(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
    body: JSON.stringify(data),
  };
    return fetch(baseApiURL + "/ProdOrder/PostTestingScanPO?userId=" + encodeURIComponent(userId), options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function printingScanner(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data 
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
    body: JSON.stringify(data),
  };
    return fetch(baseApiURL + "/ProdOrder/PrintingScanPO?userId=" + encodeURIComponent(userId), options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function visualScanner(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data 
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
    body: JSON.stringify(data),
  };
    return fetch(baseApiURL + "/ProdOrder/VisualScanPO?userId=" + encodeURIComponent(userId), options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
