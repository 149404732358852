import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Box, Select, MenuItem, Grid } from '@material-ui/core';
import ProductMCB from "./Products/ProductMCB";
import Product5ST from "./Products/Product5ST";
import ProductFuse from "./Products/ProductFuse";
import ProductFuseAccessories from "./Products/ProductFuseAccessories";
import ProductRCCB from "./Products/ProductRCCB";
import ProductRCBO from "./Products/ProductRCBO";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    },
    productNameElem : {
    width: "100%",
    },
    prodCont: {
        margin: '15px 0px',
        },
}));

export default function GRNWorkflow() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    };

    /*Yogesh 23/05/2023-- New code from tab to dropdown wise grid displying*/
    const [productName, setProductName] = useState("MCB");
    const productsData = [
        {
            value: "MCB",
            label: "MCB"
        },
        {
            value: "5ST",
            label: "5ST"
        },
        {
            value: "FUSE",
            label: "FUSE"
        },
        {
            value: "Fuse Accessories",
            label: "Fuse Accessories"
        },
        {
            value: "RCCB",
            label: "RCCB"
        },
        {
            value: "RCBO",
            label: "RCBO"
        }
    ];
  return (
    <div className={classes.root}>
          <h3>GRN Workflow</h3>
          <Grid item xs={12} md={4} fullwidth>
          <Select
                  value={productName}
                  className={classes.productNameElem}
              onChange={(e) => {
                  setProductName(e.target.value);
              }}
          >
              {productsData.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                      {item.label}
                  </MenuItem>
              ))}
          </Select>
          </Grid>

          <Grid xs={12} className={classes.prodCont}>
              {productName == "MCB" ? <ProductMCB /> : productName == "5ST" ? <Product5ST /> : productName == "FUSE" ? <ProductFuse /> : productName == "Fuse Accessories" ? <ProductFuseAccessories /> : productName == "RCCB" ? <ProductRCCB /> : productName == "RCBO" ? <ProductRCBO /> : <></>}
          </Grid>    

    </div>
  );
}
