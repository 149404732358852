import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import {
    MenuItem,
    Select,
    FormControl,
    FormHelperText,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import {
    getMlfbData,
    addMlfbData,
    deleteMlfbData,
    updateMlfbData,
    getMLFBProducts,
} from "../../../../Redux/API/api_MlfbMaster";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import { tableIcons } from "../../../../Utilities/utility";
import {
    tableOptions,
    tableCellStyleMLFB,
    tableHeaderStyleMLFB,
} from "../../../Components/CommonComponents/common_components";

export default function MLFBMaster() {
    const userdetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };

    function isInteger(value) {
        const re = /^\d*$/;
        return re.test(value);
    }
    function isAlphanumericSpecial(value) {
        const re = /^[(aA-zZ)(0-9)(\- . &)]*$/;
        return re.test(value);
    }

    function isMLFBExists(value) {
        const obj = tableData.find((c) => c.mlfb == value);
        return obj ? true : false;
    }
    function isMLFBExists(value, id) {
        const obj = tableData.find((c) => c.mlfb == value);
        return obj && obj.mlfbdetailsId !== id ? true : false;
    }
    function validateMLFB(value, id) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : (!id && isMLFBExists(value)) || (id && isMLFBExists(value, id))
                ? { isValid: false, helperText: "Already exists" }
                : { isValid: true, helperText: "" };
    }
    function validateMaterialNo(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isInteger(value)
                ? { isValid: false, helperText: "Only integers" }
                : { isValid: true, helperText: "" };
    }
    function validateFertMaterialCode(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isInteger(value)
                ? { isValid: false, helperText: "Only integers" }
                : { isValid: true, helperText: "" };
    }

    function validatePoleQty(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isInteger(value)
                ? { isValid: false, helperText: "Only integers" }
                : { isValid: true, helperText: "" };
    }
    function validateBreakerQty(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isInteger(value)
                ? { isValid: false, helperText: "Only integers" }
                : { isValid: true, helperText: "" };
    }
    function validatePackingVariant(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isAlphanumericSpecial(value)
                ? { isValid: false, helperText: "Alphanumerics and [space.-&]" }
                : { isValid: true, helperText: "" };
    }

    function getProductDDLComponent(objProps) {
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    value={objProps.value}
                    onChange={(e) => {
                        objProps.onChange(e.target.value);
                    }}
                >
                    {productsData.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
            </FormControl>
        );
    }

    function renderProductValue(value) {
        let obj = {};
        if (productsData && productsData.length > 0) {
            obj = productsData.find((c) => c.value == value);
        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }

    function validateProduct(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }

    var columns = [
        {
            title: "MLFB Id",
            field: "mlfbdetailsId",
            hidden: true,
        },
        {
            title: "MLFB",
            field: "mlfb",
            cellStyle: tableCellStyleMLFB,
            headerStyle: tableHeaderStyleMLFB,
            validate: (rowData) => validateMLFB(rowData.mlfb, rowData.mlfbdetailsId),
        },
        {
            title: "Product",
            field: "productId",
            render: (rowData) => <span>{renderProductValue(rowData.productId)}</span>,
            editComponent: (props) => getProductDDLComponent(props),
            validate: (rowData) => validateProduct(rowData.productId),
            customFilterAndSearch: (term, rowData) =>
                renderProductValue(rowData.productId)
                    .toLowerCase()
                    .includes(term.toLowerCase()),
        },
        {
            title: "Material Number",
            field: "materialNo",
            type: "numeric",
            validate: (rowData) => validateMaterialNo(rowData.materialNo),
        },
        {
            title: "Fert Material Code",
            field: "fertMaterialCode",
            validate: (rowData) => validateFertMaterialCode(rowData.fertMaterialCode),
        },
        {
            title: "Family",
            field: "family",
        },
        {
            title: "Lot Size in Pole",
            field: "lotSizeInpoles",
            type: "numeric",
            validate: (rowData) => validatePoleQty(rowData.lotSizeInpoles),
        },
        {
            title: "Lot Size in Breaker",
            field: "lotsizeInBreaker",
            type: "numeric",
            validate: (rowData) => validateBreakerQty(rowData.lotsizeInBreaker),
        },
        {
            title: "Packing Variant",
            field: "packingVariant",
            validate: (rowData) => validatePackingVariant(rowData.packingVariant),
        },
        {
            title: "Location1",
            field: "location1",
        },
        {
            title: "Location2",
            field: "location2",
        },
        {
            title: "Location3",
            field: "location3",
        },
    ];

    const [tableData, setTableData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const [productsData, setProductsData] = useState([]);

    function getTableData() {
        setIsLoader(true);
        getMlfbData(
            siteToken,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            azureToken.token
        )
            .then((response) => {
                setTableData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }

    async function getProductDetails() {
        const response = await getMLFBProducts(
            siteToken,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            UID.id,
            userdetails.roleId,
            azureToken.token
        );
        const productItems = response.map((item) => ({
            value: item.id,
            label: item.name,
        }));
        setProductsData(productItems);
    }

    const enterKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", enterKeyDown);

        getTableData();
        getProductDetails();

        return () => {
            document.removeEventListener("keydown", enterKeyDown);
        };
    }, [userdetails]);

    const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {
        setIsLoader(true);
        let errorList = [];
        if (errorList.length < 1) {
            newTableData.loggedinUserId = userdetails.userId;
            updateMlfbData(
                siteToken,
                azureToken.token,
                UID.id,
                userdetails.userEmail,
                userdetails.gid,
                userdetails.roleName,
                userdetails.roleId,
                newTableData
            )
                .then((response) => {
                    if (response.response) {
                        handleSnackOpen(response.responseMsg, "success");
                        resolve();
                        setIsLoader(false);
                        getTableData();
                    } else {
                        handleSnackOpen(response.responseMsg, "error");
                        reject();
                        setIsLoader(false);
                    }
                })
                .catch((error) => {
                    handleSnackOpen("Exception : " + error, "error");
                    reject();
                    setIsLoader(false);
                });
        } else {
            reject();
            setIsLoader(false);
            setErrorMessages(errorList);
            setIserror(true);
        }
    };
    const handleRowAdd = (newTableData, resolve, reject) => {
        setIsLoader(true);
        let errorList = [];
        if (errorList.length < 1) {
            newTableData.loggedinUserId = userdetails.userId;
            addMlfbData(
                siteToken,
                azureToken.token,
                UID.id,
                userdetails.userEmail,
                userdetails.gid,
                userdetails.roleName,
                userdetails.roleId,
                newTableData
            )
                .then((response) => {
                    if (response.response) {
                        handleSnackOpen(response.responseMsg, "success");
                        resolve();
                        setIsLoader(false);
                        getTableData();
                    } else {
                        handleSnackOpen(response.responseMsg, "error");
                        reject();
                        setIsLoader(false);
                    }
                })
                .catch((error) => {
                    handleSnackOpen("Exception : " + error, "error");
                    reject();
                    setIsLoader(false);
                });
        } else {
            reject();
            setErrorMessages(errorList);
            setIserror(true);
            setIsLoader(false);
        }
    };
    const handleRowDelete = (oldTableData, resolve, reject) => {
        setIsLoader(true);
        oldTableData.loggedinUserId = userdetails.userId;
        deleteMlfbData(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            oldTableData
            
        )
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };

    function getDisableTooltip(value, type) {
        return value === true
            ? "MLFB already associated with live production orders"
            : type === "Edit"
                ? "Edit"
                : "Delete";
    }

    return (
        <div>
            <div className="App">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <MaterialTable
                            title=""
                            columns={columns}
                            data={tableData}
                            icons={tableIcons}
                            isLoading={isLoader}
                            options={tableOptions}
                            editable={{
                                isDeletable: (rowData) => !rowData.isReadOnlyMLFB,
                                deleteTooltip: (rowData) =>
                                    getDisableTooltip(rowData.isReadOnlyMLFB, "Delete"),

                                onRowUpdate: (newTableData, oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowUpdate(
                                            newTableData,
                                            oldTableData,
                                            resolve,
                                            reject
                                        );
                                    }),
                                onRowAdd: (newTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowAdd(newTableData, resolve, reject);
                                    }),
                                onRowDelete: (oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowDelete(oldTableData, resolve, reject);
                                    }),
                            }}
                        />
                    </Grid>
                </Grid>
                <>
                    <SnackbarCustom
                        open={open}
                        message={snackMessage}
                        alertType={alertType}
                        handleClose={handleClose}
                    />
                </>
            </div>
        </div>
    );
}
