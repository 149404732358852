import React, { useEffect, useState } from "react";
import {
    Grid,
    Paper,
    TextField,
    MenuItem,
    Button,
    makeStyles,
} from "@material-ui/core";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from 'moment';
import DateFnsUtils from "@date-io/date-fns";
import FileSaver from "file-saver";
import { deletedProdOrdersReport } from "../../../../Redux/API/api_reports";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loading/Loading";
import Snackbar from "../../../Components/Snackbar/Snackbar";
const useStyles = makeStyles(() => ({
    paperSty: {
        padding: "30px",
        marginTop: "10px",
    },
    btnGrid: {
        justifyContent: "center",
        marginTop: "2rem",
    },
}));

export default function DeletedProdOrders(props) {
    const classes = useStyles();
    const [isLoader, setIsLoader] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const [supplierData, setSupplierData] = useState([]);
    const [family, setFamily] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [fromDateError, setFromDateError] = useState(null);
    const [toDateError, setToDateError] = useState(null);
    const userdetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
    const [inputData, setInputData] = React.useState({
        fromDate: "",
        toDate: "",
        supplierId: null,
        productId: null,
        loggedInUserId: userdetails.userId,
    });
    const handleSnackOpen = () => {
        setOpenSnackbar(true);
    };
    const handleCloseSnackbar = (e) => {
        setOpenSnackbar(false);
    };

    function DeletedProdOrders() {
        inputData.fromDate = !inputData.fromDate
            ? null
            : moment(fromDate).format("YYYY-MM-DD");
        inputData.toDate = !inputData.toDate
            ? null
            : moment(toDate).format("YYYY-MM-DD");
        inputData.productId = !inputData.productId
            ? null
            : parseInt(inputData.productId);
        inputData.supplierId = !inputData.supplierId
            ? null
            : parseInt(inputData.supplierId);
        deletedProdOrdersReport(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            inputData

        )
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.byteLength <= 500) {
                        setIsLoader(false);
                        handleSnackOpen();
                        setSnackMessage("No Records are Found ");
                        setAlertType("success");
                    } else {
                        var blob = new Blob([response.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        });
                        FileSaver.saveAs(blob, "DeletedProductionOrdersReport.xlsx");
                        setIsLoader(false);
                        handleSnackOpen();
                        setSnackMessage("Deleted ProductionOrders Report Downloaded");
                        setAlertType("success");
                    }
                } else {
                    setIsLoader(false);
                    handleSnackOpen();
                    setSnackMessage("Report download failed");
                    setAlertType("error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen();
                setSnackMessage("Error: ", error);
                setAlertType("error");
            });
    }

    function handleChange(e) {
        const value = e.target.value;
        setInputData({
            ...inputData,
            [e.target.name]: value,
        });
    }

    const handleFromDateChange = (e) => {
        const value = e;
        setFromDate(e);
        setFromDateError("");
        setToDateError("");
        setInputData({
            ...inputData,
            fromDate: value,
        });
    };
    const handleToDateChange = (e) => {
        const value = e;
        setToDate(e);
        setToDateError("");
        setInputData({
            ...inputData,
            toDate: value,
        });
    };
    const downloadReport = (e) => {
        setIsLoader(true);
        if (fromDate || toDate) {
            if (fromDate) {
                if (!toDate) {
                    e.preventDefault();
                    setToDateError("Mandatory field**");
                    setIsLoader(false);
                    return;
                } else {
                    DeletedProdOrders();
                }
            }
            if (toDate) {
                if (!fromDate) {
                    e.preventDefault();
                    setFromDateError("Mandatory field**");
                    setIsLoader(false);
                    return;
                } else {
                    DeletedProdOrders();
                }
            }
        } else {
            DeletedProdOrders();
        }
    };

    useEffect(() => {
        if (props) {
            setFamily(props.productData);
            setSupplierData(props.supplierData);
        }
    }, [props]);

    function DisableBeforeAndSpecific(date, disableBefore) {
        if (disableBefore) {
            const saidDate = new Date(disableBefore);
            if (date < saidDate) {
                return date < saidDate;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    return (
        <Paper className={classes.paperSty}>
            <form>
                <Grid container spacing={2} className={classes.childGrid}>
                    <Grid item xs={12} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} variant={"filled"}>
                            <KeyboardDatePicker
                                name="fromDate"
                                margin="normal"
                                fullWidth
                                clearable
                                id="date-picker-dialog"
                                label="&nbsp;&nbsp;From Date"
                                format="dd/MM/yyyy"
                                inputVariant="filled"
                                value={fromDate}
                                error={fromDateError && fromDateError.length > 0}
                                onChange={handleFromDateChange}
                                helperText={fromDateError}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                InputProps={{ readOnly: true }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} variant={"filled"}>
                            <KeyboardDatePicker
                                name="toDate"
                                margin="normal"
                                fullWidth
                                clearable
                                id="date-picker-dialog"
                                label="&nbsp;&nbsp;To Date"
                                inputVariant="filled"
                                format="dd/MM/yyyy"
                                value={toDate}
                                onChange={handleToDateChange}
                                error={toDateError && toDateError.length > 0}
                                helperText={toDateError}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                disabled={!fromDate}
                                shouldDisableDate={(date) =>
                                    DisableBeforeAndSpecific(date, fromDate)
                                }
                                InputProps={{ readOnly: true }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField
                            className="mt-1"
                            name="productId"
                            id="standard-select-currency"
                            select
                            fullWidth
                            label="Product Family"
                            value={inputData.productFamily}
                            onChange={handleChange}
                            variant="filled"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {family.map((item) => (
                                <MenuItem key={item.productId} value={item.productId}>
                                    {item.productName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField
                            className="mt-1"
                            name="supplierId"
                            id="supplier"
                            select
                            fullWidth
                            label="Supplier"
                            value={inputData.supplierCode}
                            onChange={handleChange}
                            variant="filled"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {supplierData.map((item) => (
                                <MenuItem
                                    key={item.supplierDetailsId}
                                    value={item.supplierDetailsId}
                                >
                                    {item.supplierName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.btnGrid}>
                    <Button
                        onClick={downloadReport}
                        className="pt-button--secondary bulk-upload-submit-btn"
                        type="button"
                    >
                        Download
                    </Button>
                </Grid>
                <Snackbar
                    open={openSnackbar}
                    message={snackMessage}
                    alertType={alertType}
                    handleClose={handleCloseSnackbar}
                />
                {isLoader ? <Loader /> : <React.Fragment></React.Fragment>}
            </form>
        </Paper>
    );
}
