import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Box, Grid } from "@material-ui/core";
import StageMCB from "./StageProducts/StageMCB";
import Stage5ST from "./StageProducts/Stage5ST";
import StageFuse from "./StageProducts/StageFuse";
import StageFuseAccesories from "./StageProducts/StageFuseAccesories";
import StageRCBO from "./StageProducts/StageRCBO";
import StageRCCB from "./StageProducts/StageRCCB";
import { useSelector } from "react-redux";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function StageConfiguration() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const userdetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid item xs={12} className="d-flex jc-space-bt">
        <h3>Stage Configuration</h3>
        {userdetails.roleId === 3 ? (
          <h5 className="clr-red">
            *Disclaimer: Logistics team has readonly access to this section
          </h5>
        ) : (
          <></>
        )}
      </Grid>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="MCB Scrollable List"
        >
          <Tab label="MCB" {...a11yProps(0)} />
          <Tab label="5ST" {...a11yProps(1)} />
          <Tab label="Fuse" {...a11yProps(2)} />
          <Tab label="Fuse Accessories" {...a11yProps(3)} />
          <Tab label="RCCB" {...a11yProps(4)} />
          <Tab label="RCBO" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <StageMCB />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Stage5ST />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <StageFuse />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <StageFuseAccesories />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <StageRCCB />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <StageRCBO />
      </TabPanel>
    </div>
  );
}
