import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  TextField,
  MenuItem,
  Button,
  makeStyles,
} from "@material-ui/core";
import FileSaver from "file-saver";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Loading/Loading";
import Snackbar from "../../../Components/Snackbar/Snackbar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { prodLiveWip } from "../../../../Redux/API/api_reports";

const useStyles = makeStyles(() => ({
  paperSty: {
    padding: "30px",
    marginTop: "10px",
  },
  btnGrid: {
    justifyContent: "center",
    marginTop: "2rem",
  },
}));

export default function LiveWIP(props) {
  const classes = useStyles();
  const [isLoader, setIsLoader] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [stageData, setStageData] = useState([]);
  const [mlfbData, setmlfbData] = useState([]);
  const [stageError, setStageError] = useState(null);
  const [istageFilled, setIsStageFilled] = useState(false);
  const siteToken = useSelector((state) => state.saveTokenReducer);
  const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const [supplierData, setSupplierData] = useState([]);
  const [stagename, setStage] = useState("");
  const [family, setFamily] = useState([]);
  const userdetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
  const [inputData, setInputData] = React.useState({
    productionOrderNumber: "",
    fertMaterialCode: "",
    supplierId: null,
    mlfb: null,
    productId: null,
    stageId: null,
    loggedInUserId: userdetails.userId,
  });

  const handleSnackOpen = () => {
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = (e) => {
    setOpenSnackbar(false);
  };
  function getLiveWip() {
    inputData.productId = !inputData.productId
      ? null
      : parseInt(inputData.productId);
    inputData.stageId = !inputData.stageId ? null : parseInt(inputData.stageId);
    inputData.supplierId = !inputData.supplierId
      ? null
      : parseInt(inputData.supplierId);
    prodLiveWip(
        siteToken,
        azureToken.token,
        UID.id,
        userdetails.userEmail,
        userdetails.gid,
        userdetails.roleName,
        userdetails.roleId,
        inputData
     
    )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.byteLength <= 500) {
            setIsLoader(false);
            handleSnackOpen();
            setSnackMessage("No Records are Found ");
            setAlertType("success");
          } else {
            var blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(blob, "LiveWIPPReport.xlsx");
            setIsLoader(false);
            handleSnackOpen();
            setSnackMessage("Live Wip Report downloaded");
            setAlertType("Success");
          }
        } else {
          setIsLoader(false);
          handleSnackOpen();
          setSnackMessage("Report download failed");
          setAlertType("Alert");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        handleSnackOpen();
        setSnackMessage("Error: ", error);
        setAlertType("error");
      });
  }

  function handleChange(e) {
    const value = e.target.value;
    setInputData({
      ...inputData,
      [e.target.name]: value,
    });
  }

  function handlemlfbChange(e, newValue) {
    if (newValue) {
      setInputData({
        ...inputData,
        mlfb: newValue.mlfbdetailsId,
      });
    }
  }
  const handleStageChange = (e) => {
    const value = e.target.value;
    setStage(value);
    setStageError("");
    setIsStageFilled(true);
    setInputData({
      ...inputData,
      stageId: value,
    });
  };
  const downloadReport = (e) => {
    setIsLoader(true);
    if (!stagename) {
      e.preventDefault();
      setIsLoader(false);
      setStageError("Mandatory field**");
      return;
    } else {
      getLiveWip();
    }
  };

  useEffect(() => {
    if (props) {
      setmlfbData(props.mlfbData);
      setFamily(props.productData);
      setSupplierData(props.supplierData);
      setStageData(props.stageData);
    }
  }, [props]);

  return (
    <Paper className={classes.paperSty}>
      <form>
        <Grid container spacing={2} className={classes.childGrid}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              name="productionOrderNumber"
              type="text"
              label="Production Order No"
              variant="filled"
              value={inputData.productionOrderNumber}
              onChange={handleChange}
              id="productionOrderNumber"
              className=""
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="fertMaterialCode"
              fullWidth
              type="text"
              label="FERT Material Code"
              variant="filled"
              value={inputData.fertMaterialCode}
              onChange={handleChange}
              id="FERTMaterialCode"
              className=""
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              fullWidth
              name="mlfb"
              select
              loading
              id="mlfb"
              freeSolo
              margin="normal"
              options={mlfbData}
              onChange={handlemlfbChange}
              getOptionLabel={(option) => option.mlfb}
              renderInput={(params) => (
                <TextField {...params} variant="filled" label="MLFB" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="productId"
              id="standard-select-currency"
              select
              fullWidth
              label="Product Family"
              value={inputData.productId}
              onChange={handleChange}
              variant="filled"
            >
              <MenuItem value="">
                <em>none</em>
              </MenuItem>
              {family.map((item) => (
                <MenuItem key={item.productId} value={item.productId}>
                  {item.productName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="stageId"
              fullWidth
              select
              label="Stage"
              variant="filled"
              error={stageError}
              value={stagename}
              helperText={stageError}
              onChange={handleStageChange}
              className=""
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {stageData.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="supplierId"
              id="supplier"
              select
              fullWidth
              label="Supplier"
              value={inputData.supplierCode}
              onChange={handleChange}
              variant="filled"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {supplierData.map((item) => (
                <MenuItem
                  key={item.supplierDetailsId}
                  value={item.supplierDetailsId}
                >
                  {item.supplierName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.btnGrid}>
          <Button
            onClick={downloadReport}
            className="pt-button--secondary bulk-upload-submit-btn"
          >
            Download
          </Button>
        </Grid>

        <Snackbar
          open={openSnackbar}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleCloseSnackbar}
        />
        {isLoader ? <Loader /> : <React.Fragment></React.Fragment>}
      </form>
    </Paper>
  );
}
