import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import {
    getPoListDataDispatch,
    addDispatchData,
    updateDispatchData,
} from "../../../Redux/API/api_Supplier";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    DatePicker,
    TimePicker,
} from "@material-ui/pickers";
import { tableIcons } from "../../../Utilities/utility";
import { tableOptions } from "../../Components/CommonComponents/common_components";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";

export default function DispatchDetails() {
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const handleClose = (e) => {
        setOpen(false);
    };
    let history = useHistory();

    function getGenerateQRButton(rowData) {
        return <Button className="pt-button--tertiary">Generate</Button>;
    }
    function clickEventGenerateQR(data) {
        history.push({
            pathname: "/generateQR",
            state: {
                rowData: data,
            },
        });
    }
    function renderUnitPriceWithThousandAndDecimalSeparator(value) {
        return !value
            ? value
            : value
                .toLocaleString("en-US", { minimumFractionDigits: 2 })
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
    function isValidDecimal(value) {
        return /^(\d*|\d*\.\d{1,2})$/g.test(value);
    }
    function max100CharactersAllowed(value) {
        return value.toString().length > 100 ? false : true;
    }
    function isValidInteger(value) {
        return /^(\d{0,2})$/g.test(value);
    }
    function isValidDispatchQtyWith10PercentTollrence(data) {
        return (
            data.dispatchQty >= data.breakerQty - data.breakerQty / 10 &&
            data.dispatchQty <= data.breakerQty
        );
    }
    function validateDispatchQunatity(data) {
        return !data.dispatchQty
            ? { isValid: false, helperText: "*Required" }
            : !isValidDispatchQtyWith10PercentTollrence(data)
                ? { isValid: false, helperText: "Max tolerance -10%" }
                : { isValid: true, helperText: "" };
    }
    function validateInvoiceNumber(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }
    function validateUnitPrice(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isValidDecimal(value)
                ? { isValid: false, helperText: "Not allowed" }
                : { isValid: true, helperText: "" };
    }
    function validateTrip(value) {
        return value && !isValidInteger(value)
            ? { isValid: false, helperText: "Not allowed" }
            : { isValid: true, helperText: "" };
    }
    function getDispatchTimeComponent(objProps) {
        const value = objProps.rowData.time;
        let errorText = "";
        if (!value) {
            errorText = "*Required";
        } else {
            errorText = "";
        }

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                    orientation="portrait"
                    fullWidth
                    clearable
                    ampm={false}
                    invalidLabel="Invalid Time"
                    emptyLabel="Delivery Time"
                    format="HH:mm"
                    value={objProps.value}
                    helperText={errorText}
                    onChange={(time) => {
                        objProps.onChange(time);
                    }}
                />
            </MuiPickersUtilsProvider>
        );
    }
    function validateDeliveryTime(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : value === "Invalid Date"
                ? { isValid: false, helperText: "Invalid time" }
                : { isValid: true, helperText: "" };
    }
    function renderDeliveryTime(value) {
        var dateTime = new Date(value);
        return value
            ? (dateTime.getHours() < 10 ? "0" : "") +
            dateTime.getHours().toString() +
            ":" +
            (dateTime.getMinutes() < 10 ? "0" : "") +
            dateTime.getMinutes().toString()
            : "";
    }
    function getDispatchDateComponent(objProps) {
        const value = objProps.rowData.dispatchDate;
        let errorText = "";
        if (!value) {
            errorText = "*Required";
        } else {
            errorText = "";
        }

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    orientation="portrait"
                    fullWidth
                    clearable
                    autoOk
                    invalidLabel="Invalid Date"
                    emptyLabel="Dispatch Date"
                    format="MM/dd/yyyy"
                    disablePast
                    value={objProps.value ? objProps.value : null}
                    required
                    helperText={errorText}
                    onChange={(date) => {
                        const newDate = new Date(date ? date.toDateString() : date);
                        objProps.onChange(
                            date
                                ? new Date(newDate.setMinutes(newDate.getMinutes() + 340))
                                : date
                        );
                    }}
                    views={["year", "month", "date"]}
                />
            </MuiPickersUtilsProvider>
        );
    }
    function validateDispatchDate(value) {
        return !value ? false : true;
    }
    function validateRemarks(value) {
        return value && !max100CharactersAllowed(value)
            ? { isValid: false, helperText: "Max 100 characters." }
            : { isValid: true, helperText: "" };
    }
    var columns = [
        {
            title: "Production Order",
            field: "productionOrderNumber",
            editable: "never",
        },
        {
            title: "Material No.",
            field: "materialNumber",
            editable: "never",
        },
        {
            title: "MLFB",
            field: "mlfb",
            cellStyle: {
                minWidth: "9rem",
                maxWidth: "9rem",
            },

            editable: "never",
        },
        {
            title: "MLFB ID",
            field: "mlfbId",
            hidden: true,
        },
        {
            title: "Breaker Quantity",
            field: "breakerQty",
            editable: "never",
        },
        {
            title: "Dispatch Quantity",
            field: "dispatchQty",
            type: "numeric",
            validate: (rowData) => validateDispatchQunatity(rowData),
        },
        {
            title: "Invoice No.",
            field: "invoiceNo",
            validate: (rowData) => validateInvoiceNumber(rowData.invoiceNo),
        },
        {
            title: "Unit Price",
            field: "unitPrice",
            type: "numeric",
            validate: (rowData) => validateUnitPrice(rowData.unitPrice),
            render: (rowData) =>
                renderUnitPriceWithThousandAndDecimalSeparator(rowData.unitPrice),
        },
        {
            title: "Trip",
            field: "trip",
            type: "numeric",
            validate: (rowData) => validateTrip(rowData.trip),
        },
        {
            title: "Delivery Time(24 Hours)",
            field: "time",
            validate: (rowData) => validateDeliveryTime(rowData.time),
            editComponent: (props) => getDispatchTimeComponent(props),
            render: (rowData) => renderDeliveryTime(rowData.time),
        },
        {
            title: "Dispatch Date",
            field: "dispatchDate",
            validate: (rowData) => validateDispatchDate(rowData.dispatchDate),
            editComponent: (props) => getDispatchDateComponent(props),
        },
        {
            title: "Remarks",
            field: "remarks",
            validate: (rowData) => validateRemarks(rowData.remarks),
        },
        {
            title: "Supplier ID",
            field: "supplierId",
            hidden: true,
        },
        {
            title: "DispatchId",
            field: "dispatchId",
            hidden: true,
        },
        {
            title: "ProductionOrderId",
            field: "productionOrderId",
            hidden: true,
        },
        {
            title: "Purchase Order Number",
            field: "purchaseOrderNumber",
            hidden: true,
        },
        {
            title: "Generate QR",
            field: "",
            readonly: true,
            render: (rowData) => getGenerateQRButton(rowData),
            hidden: true,
        },
    ];
    let actions = [
        (rowData) => ({
            disabled: rowData.dispatchId && rowData.dispatchId > 0 ? false : true,
            icon: (rowData) => getGenerateQRButton(rowData),
            tooltip:
                rowData.dispatchId && rowData.dispatchId > 0
                    ? "Generate QR"
                    : "Dispatch details mandatory",
            onClick: (event, rowData) => clickEventGenerateQR(rowData),
        }),
    ];

    const [dispatchTableData, setDispatchTableData] = useState([]);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const userdetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    const [isLoader, setIsLoader] = useState(false);

    const handleRowUpdate = (newData, oldData, resolve, reject) => {
        setIsLoader(true);
        let errorList = [];
        if (errorList.length < 1) {
            newData.loggedinUserId = userdetails.userId;
            newData.supplierId = userdetails.supplierId;
            newData.dispatchQty = parseInt(newData.dispatchQty);
            newData.unitPrice = parseFloat(newData.unitPrice);
            newData.trip = newData.trip ? newData.trip.toString() : "";
            if (newData.dispatchId && newData.dispatchId > 0) {
                updateDispatchData(
                    siteToken,
                    azureToken.token,
                    UID.id,
                    userdetails.userEmail,
                    userdetails.gid,
                    userdetails.roleName,
                    userdetails.roleId,
                    newData
                   
                   
                )
                    .then((response) => {
                        if (response.response) {
                            handleSnackOpen(response.responseMsg, "success");
                            resolve();
                            getTableData();
                        } else {
                            handleSnackOpen(response.responseMsg, "error");
                            reject();
                            setIsLoader(false);
                        }
                    })
                    .catch((error) => {
                        handleSnackOpen("Error : " + error, "error");
                        reject();
                        setIsLoader(false);
                    });
            } else {
                addDispatchData(
                    siteToken,
                    azureToken.token,
                    UID.id,
                    userdetails.userEmail,
                    userdetails.gid,
                    userdetails.roleName,
                    userdetails.roleId,
                    newData

                )
                    .then((response) => {
                        if (response.response) {
                            handleSnackOpen(response.responseMsg, "success");
                            resolve();
                            getTableData();
                        } else {
                            handleSnackOpen(response.responseMsg, "error");
                            reject();
                            setIsLoader(false);
                        }
                    })
                    .catch((error) => {
                        handleSnackOpen("Error : " + error, "error");
                        reject();
                        setIsLoader(false);
                    });
            }
        }
    };

    function getTableData() {
        setIsLoader(true);
        if (userdetails && userdetails.supplierId) {
            getPoListDataDispatch(
                siteToken,
                userdetails.supplierId,
                UID.id,
                userdetails.userEmail,
                userdetails.gid,
                userdetails.roleName,
                userdetails.roleId,
                azureToken.token
            )
                .then((response) => {
                    setDispatchTableData(response);
                    setIsLoader(false);
                })
                .catch((error) => {
                    setIsLoader(false);
                    handleSnackOpen("Error while fetching data from server", "error");
                });
        } else {
            setIsLoader(false);
            handleSnackOpen(
                "User must be assigned with a supplier code to Dispatch data",
                "info"
            );
        }
    }

    useEffect(() => {
        getTableData();
    }, [userdetails]);

    return (
        <>
            <Grid>
                <h3>Dispatch Details</h3>
                <MaterialTable
                    title=""
                    columns={columns}
                    data={dispatchTableData}
                    actions={actions}
                    icons={tableIcons}
                    isLoading={isLoader}
                    localization={{
                        header: {
                            actions: "Generate QR or Edit Dispatch Details",
                        },
                    }}
                    options={tableOptions}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                handleRowUpdate(newData, oldData, resolve, reject);
                            }),
                    }}
                />

                <div className="ackFooter">
                    <Button
                        className="pt-button--secondary"
                        onClick={() => {
                            history.push({
                                pathname: "/ackorder",
                            });
                        }}
                    >
                        Back
                    </Button>
                </div>
            </Grid>
            <>
                <SnackbarCustom
                    open={open}
                    message={snackMessage}
                    alertType={alertType}
                    handleClose={handleClose}
                />
            </>
        </>
    );
}
