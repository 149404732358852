import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import MaterialTable, { MTableBody } from "material-table";

import { tableIcons } from "../../../Utilities/utility";
import { getFuseWorkflowData } from "../../../Redux/API/api_Workflow";
import { Button, TableCell, TableBody, TableRow, Box } from "@material-ui/core";
import {
  tableOptions,
  tableHeaderStyleMLFB,
  tableCellStyleMLFB,
} from "../../Components/CommonComponents/common_components";
import Tooltip from "@material-ui/core/Tooltip";

export default function ProductFuse() {
  const siteToken = useSelector((state) => state.saveTokenReducer);
  const [fuseData, setFuseData] = useState([]);

  const userdetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const tableRef = React.useRef();
    const [isTableLoader, setIsTableLoader] = useState(false);
    const [isHidePOInitiate, setIsHidePOInitiate] = useState(true);
    const [isHidePOAcknowledge, setIsHidePOAcknowledge] = useState(true);
    const [isHideDispatchRemarks, setIsHideDispatchRemarks] = useState(true);
    const [isHidePODispatch, setIsHidePODispatch] = useState(true);
    const [isHideGRN, setIsHideGRN] = useState(true);
    const [isHideBL1, setIsHideBL1] = useState(true);
    const [isHideBL2, setIsHideBL2] = useState(true);
    const [isHideTesting, setIsHideTesting] = useState(true);
    const [isHidePostTesting, setIsHidePostTesting] = useState(true);
    const [isHidePacking, setIsHidePacking] = useState(true);
    const [isHidePrinting, setIsHidePrinting] = useState(true);
    const [isHideVisual, setIsHideVisual] = useState(true);
    const [isHidePD, setIsHidePD] = useState(true);
    const [isHideBDC, setIsHideBDC] = useState(true);

    const [wipData, setWIPData] = useState([]);

    function getWIPCount(data) {
      setIsTableLoader(true);
      let mcbObject = {
        total: 0,
        poInitiate: 0,
        poAcknowledge: 0,
        poDispatch: 0,
        grn: 0,
        bufferLocation1: 0,
        testing: 0,
        bufferLocation2: 0,
        postTesting: 0,
        printing: 0,
        visual: 0,
        packing: 0,
        pd: 0,
        bdc: 0,
      };

      data.map((item) => {
        mcbObject.total = mcbObject.total + parseInt(item.brkQty);
        switch (item.currentStage) {
          case "POInitiate":
            mcbObject.poInitiate = mcbObject.poInitiate + parseInt(item.brkQty);
            break;
          case "POAcknowledge":
            mcbObject.poAcknowledge =
              mcbObject.poAcknowledge + parseInt(item.brkQty);
            break;
          case "PODispatch":
            mcbObject.poDispatch = mcbObject.poDispatch + parseInt(item.brkQty);
            break;
          case "GRN":
            mcbObject.grn = mcbObject.grn + parseInt(item.brkQty);
            break;
          case "BufferLocation1":
            mcbObject.bufferLocation1 =
              mcbObject.bufferLocation1 + parseInt(item.brkQty);
            break;
          case "Testing":
            mcbObject.testing = mcbObject.testing + parseInt(item.brkQty);
            break;
          case "BufferLocation2":
            mcbObject.bufferLocation2 =
              mcbObject.bufferLocation2 + parseInt(item.brkQty);
            break;
          case "PostTesting":
            mcbObject.postTesting =
              mcbObject.postTesting + parseInt(item.brkQty);
            break;
          case "Printing":
            mcbObject.printing = mcbObject.printing + parseInt(item.brkQty);
            break;
          case "Visual":
            mcbObject.visual = mcbObject.visual + parseInt(item.brkQty);
            break;
          case "Packing":
            mcbObject.packing = mcbObject.packing + parseInt(item.brkQty);
            break;
          case "PD":
            mcbObject.pd = mcbObject.pd + parseInt(item.brkQty);
            break;
          case "BDC":
            mcbObject.bdc = mcbObject.bdc + parseInt(item.brkQty);
            break;
          default:
            break;
        }
        return item;
      });

      setWIPData([{ ...mcbObject }]);
      setIsTableLoader(false);
    }

    function HideColumns(data) {
      let objIsExist = data.find(
        (x) => x.poInitiate !== "NA" && x.poInitiate !== "InActive"
      );
      setIsHidePOInitiate(!objIsExist);

      objIsExist = data.find(
        (x) => x.poAcknowledge !== "NA" && x.poAcknowledge !== "InActive"
      );
      setIsHidePOAcknowledge(!objIsExist);

      objIsExist = data.find(
        (x) => x.poDispatch !== "NA" && x.poDispatch !== "InActive"
      );
      setIsHidePODispatch(!objIsExist);

      objIsExist = data.find((x) => x.grn !== "NA" && x.grn !== "InActive");
      setIsHideGRN(!objIsExist);

      if (userdetails.roleId === 2) {
        setIsHideBL1(true);
        setIsHideTesting(true);
        setIsHideBL2(true);
        setIsHidePostTesting(true);
        setIsHidePrinting(true);
        setIsHideVisual(true);
        setIsHidePacking(true);
        setIsHidePD(true);
        setIsHideBDC(true);
      } else {
        objIsExist = data.find(
          (x) => x.bufferLocation1 !== "NA" && x.bufferLocation1 !== "InActive"
        );
        setIsHideBL1(!objIsExist);

        objIsExist = data.find(
          (x) => x.testing !== "NA" && x.testing !== "InActive"
        );
        setIsHideTesting(!objIsExist);

        objIsExist = data.find(
          (x) => x.bufferLocation2 !== "NA" && x.bufferLocation2 !== "InActive"
        );
        setIsHideBL2(!objIsExist);

        objIsExist = data.find(
          (x) => x.postTesting !== "NA" && x.postTesting !== "InActive"
        );
        setIsHidePostTesting(!objIsExist);

        objIsExist = data.find(
          (x) => x.printing !== "NA" && x.printing !== "InActive"
        );
        setIsHidePrinting(!objIsExist);

        objIsExist = data.find(
          (x) => x.visual !== "NA" && x.visual !== "InActive"
        );
        setIsHideVisual(!objIsExist);

        objIsExist = data.find(
          (x) => x.packing !== "NA" && x.packing !== "InActive"
        );
        setIsHidePacking(!objIsExist);

        objIsExist = data.find((x) => x.pd !== "NA" && x.pd !== "InActive");
        setIsHidePD(!objIsExist);

        objIsExist = data.find((x) => x.bdc !== "NA" && x.bdc !== "InActive");
        setIsHideBDC(!objIsExist);
      }
    }
    function renderWIPWithThousandSeparator(value) {
      return !value
        ? value
        : value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    function getFusedata() {
      setIsTableLoader(true);
      getFuseWorkflowData(
        siteToken,
        UID.id,
        userdetails.userEmail,
        userdetails.gid,
        userdetails.roleName,
        userdetails.roleId,
        azureToken.token
      )
        .then((response) => {
          if (userdetails.roleId === 2) {
            const filteredResponse = response.filter(
              (x) => x.supplierId === userdetails.supplierId
            );
            setFuseData(filteredResponse);
            HideColumns(filteredResponse);
            getWIPCount(filteredResponse);
            setIsTableLoader(false);
          } else {
            setFuseData(response);
            HideColumns(response);
            getWIPCount(response);
            setIsTableLoader(false);
          }
        })
        .catch((error) => {
          setIsTableLoader(false);
        });
    }

  useEffect(() => {
    getFusedata();
    const interval = setInterval(() => getFusedata(), 4 * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [userdetails]);

  function renderPONumber(data) {
    let poNumSpan =
      data.totalActualTimeTakenTillCurrentStage >
        data.totalPlannedLeadTimeTillCurrentStage ? (
        <span style={{ color: "rgb(254 131 137)" }}>
          {data.productionOrderNumber}
        </span>
      ) : (
        <span>{data.productionOrderNumber}</span>
      );
    return (
      <>
        {!isHideDispatchRemarks && data.dispatchRemarks ? (
          <Tooltip title={data.dispatchRemarks} placement="top">
            {poNumSpan}
          </Tooltip>
        ) : (
          poNumSpan
        )}
      </>
    );
  }
  var columns = [
    {
      title: "Production Order Number",
      field: "productionOrderNumber",
      width: "1000px",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      render: (rowData) => renderPONumber(rowData),
    },
    {
      title: "MLFB",
      field: "mlfb",
      width: "10%",
      cellStyle: tableCellStyleMLFB,
      headerStyle: tableHeaderStyleMLFB,
    },
    {
      title: "Rating",
      field: "rating",
      width: "10%",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
    },
    {
      title: "Quantity In Breaker",
      field: "brkQty",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
    },
    {
      title: "PO Initiate",
      field: "poInitiate",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      hidden: isHidePOInitiate,
      render: (rowData) => (
        <div
          style={{
            backgroundColor: rowData.poInitiateColorCode,
            minHeight: "2rem",
            padding: "8px 5px",
            width: "4em",
            textAlign: "center",
          }}
        >
          {rowData.poInitiate === "InActive" ? "" : rowData.poInitiate}
        </div>
      ),
    },
    {
      title: "PO Acknow ledge",
      field: "poAcknowledge",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      hidden: isHidePOAcknowledge,
      render: (rowData) => (
        <div
          style={{
            backgroundColor: rowData.poAcknowledgeColorCode,
            minHeight: "2rem",
            padding: "8px 5px",
            width: "4em",
            textAlign: "center",
          }}
        >
          {rowData.poAcknowledge === "InActive" ? "" : rowData.poAcknowledge}
        </div>
      ),
    },
    {
      title: "Dispatch Remarks",
      field: "dispatchRemarks",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      hidden: true,
    },
    {
      title: "PO Dispatch",
      field: "poDispatch",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      hidden: isHidePODispatch,
      render: (rowData) => (
        <div
          style={{
            backgroundColor: rowData.poDispatchColorCode,
            minHeight: "2rem",
            padding: "8px 5px",
            width: "4em",
            textAlign: "center",
          }}
        >
          {rowData.poDispatch === "InActive" ? "" : rowData.poDispatch}
        </div>
      ),
    },
    {
      title: "GRN",
      field: "grn",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      hidden: isHideGRN,
      render: (rowData) => (
        <div
          style={{
            backgroundColor: rowData.grnColorCode,
            minHeight: "2rem",
            padding: "8px 5px",
            width: "4em",
            textAlign: "center",
          }}
        >
          {rowData.grn === "InActive" ? "" : rowData.grn}
        </div>
      ),
    },
    {
      title: "Buffer Location 1",
      field: "bufferLocation1",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      hidden: isHideBL1,
      render: (rowData) => (
        <div
          style={{
            backgroundColor: rowData.bufferLocation1ColorCode,
            minHeight: "2rem",
            padding: "8px 5px",
            width: "4em",
            textAlign: "center",
          }}
        >
          {rowData.bufferLocation1 === "InActive"
            ? ""
            : rowData.bufferLocation1}
        </div>
      ),
    },
    {
      title: "Testing",
      field: "testing",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      hidden: isHideTesting,
      render: (rowData) => (
        <div
          style={{
            backgroundColor: rowData.testingColorCode,
            minHeight: "2rem",
            padding: "8px 5px",
            width: "4em",
            textAlign: "center",
          }}
        >
          {rowData.testing === "InActive" ? "" : rowData.testing}
        </div>
      ),
    },
    {
      title: "Buffer Location 2",
      field: "bufferLocation2",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      hidden: isHideBL2,
      render: (rowData) => (
        <div
          style={{
            backgroundColor: rowData.bufferLocation2ColorCode,
            minHeight: "2rem",
            padding: "8px 5px",
            width: "4em",
            textAlign: "center",
          }}
        >
          {rowData.bufferLocation2 === "InActive"
            ? ""
            : rowData.bufferLocation2}
        </div>
      ),
    },
    {
      title: "Post Testing",
      field: "postTesting",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      hidden: isHidePostTesting,
      render: (rowData) => (
        <div
          style={{
            backgroundColor: rowData.postTestingColorCode,
            minHeight: "2rem",
            padding: "8px 5px",
            width: "4em",
            textAlign: "center",
          }}
        >
          {rowData.postTesting === "InActive" ? "" : rowData.postTesting}
        </div>
      ),
    },
    {
      title: "Printing",
      field: "printing",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      hidden: isHidePrinting,
      render: (rowData) => (
        <div
          style={{
            backgroundColor: rowData.printingColorCode,
            minHeight: "2rem",
            padding: "8px 5px",
            width: "4em",
            textAlign: "center",
          }}
        >
          {rowData.printing === "InActive" ? "" : rowData.printing}
        </div>
      ),
    },
    {
      title: "Visual",
      field: "visual",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      hidden: isHideVisual,
      render: (rowData) => (
        <div
          style={{
            backgroundColor: rowData.visualColorCode,
            minHeight: "2rem",
            padding: "8px 5px",
            width: "4em",
            textAlign: "center",
          }}
        >
          {rowData.visual === "InActive" ? "" : rowData.visual}
        </div>
      ),
    },
    {
      title: "Packing",
      field: "packing",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      hidden: isHidePacking,
      render: (rowData) => (
        <div
          style={{
            backgroundColor: rowData.packingColorCode,
            minHeight: "2rem",
            padding: "8px 5px",
            width: "4em",
            textAlign: "center",
          }}
        >
          {rowData.packing === "InActive" ? "" : rowData.packing}
        </div>
      ),
    },
    {
      title: "PD",
      field: "pd",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      hidden: isHidePD,
      render: (rowData) => (
        <div
          style={{
            backgroundColor: rowData.pdColorCode,
            minHeight: "2rem",
            padding: "8px 5px",
            width: "4em",
            textAlign: "center",
          }}
        >
          {rowData.pd === "InActive" ? "" : rowData.pd}
        </div>
      ),
    },
    {
      title: "BDC",
      field: "bdc",
      cellStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      headerStyle: {
        minWidth: 100,
        maxWidth: 100,
      },
      hidden: isHideBDC,
      render: (rowData) => (
        <div
          style={{
            backgroundColor: rowData.bdcColorCode,
            minHeight: "2rem",
            padding: "8px 5px",
            width: "4em",
            textAlign: "center",
          }}
        >
          {rowData.bdc === "InActive" ? "" : rowData.bdc}
        </div>
      ),
    },
    {
      title: "CurrentStage",
      field: "currentStage",
      hidden: true,
    },
    {
      title: "TotalActualTimeTakenTillCurrentStage",
      field: "totalActualTimeTakenTillCurrentStage",
      hidden: true,
    },
    {
      title: "TotalPlannedLeadTimeTillCurrentStage",
      field: "totalPlannedLeadTimeTillCurrentStage",
      hidden: true,
    },
  ];

  return (
    <Box mb={3}>
      <MaterialTable
        tableRef={tableRef}
        onSearchChange={() => {
          getWIPCount(tableRef.current.dataManager.searchedData);
        }}
        title={
          <Button onClick={getWIPCount} className="pt-button--secondary">
            Generate Data
          </Button>
        }
        columns={columns}
        data={fuseData}
        icons={tableIcons}
        isLoading={isTableLoader}
        options={tableOptions}
        components={{
          Body: (props) => (
            <>
              <MTableBody {...props} />
              <TableBody>
                {wipData.map((row) => (
                  <TableRow className={"bg-amber"}
                    style={
                      {
                        position: 'sticky',
                        bottom: 0,
                      }
                    }
                  >
                    <TableCell
                      align="right"
                      colSpan={2}
                      style={{ fontWeight: 800, fontSize: "14px" }}
                    >
                      Total
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      style={{ fontWeight: 800, fontSize: "14px" }}
                    >
                      {renderWIPWithThousandSeparator(row.total)}
                    </TableCell>
                    {!isHidePOInitiate ? (
                      <TableCell
                        style={{
                          padding: "10px 25px",
                          fontWeight: 800,
                          fontSize: "14px",
                        }}
                        colSpan={1}
                      >
                        {renderWIPWithThousandSeparator(row.poInitiate)}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    {!isHidePOAcknowledge ? (
                      <TableCell
                        style={{
                          padding: "10px 25px",
                          fontWeight: 800,
                          fontSize: "14px",
                        }}
                        colSpan={1}
                      >
                        {renderWIPWithThousandSeparator(row.poAcknowledge)}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    {!isHidePODispatch ? (
                      <TableCell
                        style={{
                          padding: "10px 25px",
                          fontWeight: 800,
                          fontSize: "14px",
                        }}
                        colSpan={1}
                      >
                        {renderWIPWithThousandSeparator(row.poDispatch)}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    {!isHideGRN ? (
                      <TableCell
                        style={{
                          padding: "10px 25px",
                          fontWeight: 800,
                          fontSize: "14px",
                        }}
                        colSpan={1}
                      >
                        {renderWIPWithThousandSeparator(row.grn)}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    {!isHideBL1 ? (
                      <TableCell
                        style={{
                          padding: "10px 25px",
                          fontWeight: 800,
                          fontSize: "14px",
                        }}
                        colSpan={1}
                      >
                        {renderWIPWithThousandSeparator(row.bufferLocation1)}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    {!isHideTesting ? (
                      <TableCell
                        style={{
                          padding: "10px 25px",
                          fontWeight: 800,
                          fontSize: "14px",
                        }}
                        colSpan={1}
                      >
                        {renderWIPWithThousandSeparator(row.testing)}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    {!isHideBL2 ? (
                      <TableCell
                        style={{
                          padding: "10px 25px",
                          fontWeight: 800,
                          fontSize: "14px",
                        }}
                        colSpan={1}
                      >
                        {renderWIPWithThousandSeparator(row.bufferLocation2)}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    {!isHidePostTesting ? (
                      <TableCell
                        style={{
                          padding: "10px 25px",
                          fontWeight: 800,
                          fontSize: "14px",
                        }}
                        colSpan={1}
                      >
                        {renderWIPWithThousandSeparator(row.postTesting)}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    {!isHidePrinting ? (
                      <TableCell
                        style={{
                          padding: "10px 25px",
                          fontWeight: 800,
                          fontSize: "14px",
                        }}
                        colSpan={1}
                      >
                        {renderWIPWithThousandSeparator(row.printing)}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    {!isHideVisual ? (
                      <TableCell
                        style={{
                          padding: "10px 25px",
                          fontWeight: 800,
                          fontSize: "14px",
                        }}
                        colSpan={1}
                      >
                        {renderWIPWithThousandSeparator(row.visual)}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    {!isHidePacking ? (
                      <TableCell
                        style={{
                          padding: "10px 25px",
                          fontWeight: 800,
                          fontSize: "14px",
                        }}
                        colSpan={1}
                      >
                        {renderWIPWithThousandSeparator(row.packing)}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    {!isHidePD ? (
                      <TableCell
                        style={{
                          padding: "10px 25px",
                          fontWeight: 800,
                          fontSize: "14px",
                        }}
                        colSpan={1}
                      >
                        {renderWIPWithThousandSeparator(row.pd)}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    {!isHideBDC ? (
                      <TableCell
                        style={{
                          padding: "10px 25px",
                          fontWeight: 800,
                          fontSize: "14px",
                        }}
                        colSpan={1}
                      >
                        {renderWIPWithThousandSeparator(row.bdc)}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </>
          ),
        }}
      />
    </Box>
  );
}
