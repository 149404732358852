import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import {
    MenuItem,
    Select,
    TextField,
    FormControl,
    FormHelperText,
    Tooltip,
    ListItemText,
    Typography,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import { tableOptions } from "../../Components/CommonComponents/common_components";
import {
    addUserData,
    deleteUserData,
    getUserData,
    updateUserData,
} from "../../../Redux/API/api_UserManagement";
import {
    getRolesDDL,
    getSupplierCodesDDL,
} from "../../../Redux/API/api_Common";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { tableIcons } from "../../../Utilities/utility";
import InfoIcon from "@material-ui/icons/Info";
import "../UserManagementComponent/UserList.scss";

export default function UserList(props) {
    const userdetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    function isValidEmail(email) {
        const re =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        return re.test(String(email).toLowerCase());
    }
    function isValidNameField(name) {
        const re = /^[a-zA-Z ]*$/;
        return re.test(name);
    }
    function isEmailExists(value) {
        const obj = tableData.find((c) => c.userEmail === value);
        return obj ? true : false;
    }
    function isEmailExists(value, id) {
        const obj = tableData.find((c) => c.userEmail === value);
        return obj && obj.userId !== id ? true : false;
    }
    function isGIDExists(value) {
        const obj = tableData.find((c) => c.gid === value);
        return obj ? true : false;
    }
    function isGIDExists(value, id) {
        const obj = tableData.find((c) => c.gid === value);
        return obj && obj.userId !== id ? true : false;
    }
    function isValidGIDPerRoleId(value) {
        const arr = [1, 3, 4, 5, 7, 8, 19];
        return !arr.includes(value);
    }
    function isValidSupplierPerRoleId(value) {
        const arr = [1, 2];
        return !arr.includes(value);
    }
    function getGIDComponent(objProps) {
        const rowData = objProps.rowData;
        let value = rowData.gid;
        if (isValidGIDPerRoleId(rowData.roleId)) {
            value = "";
        }
        return (
            <TextField
                id="standard-basic"
                value={value}
                placeholder="GID"
                onChange={(e) => objProps.onChange(e.target.value)}
                disabled={isValidGIDPerRoleId(rowData.roleId)}
                error={!isValidGIDPerRoleId(rowData.roleId) && !value}
                helperText={
                    !isValidGIDPerRoleId(rowData.roleId) && !value
                        ? "*Required"
                        : !isValidGIDPerRoleId(rowData.roleId) &&
                            rowData.gid &&
                            ((!rowData.userId && isGIDExists(rowData.gid)) ||
                                (rowData.userId && isGIDExists(rowData.gid, rowData.userId)))
                            ? "Already Exist"
                            : ""
                }
            />
        );
    }
    function isValidPasswordPerRoleId(value) {
        const arr = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
        return !arr.includes(value);
    }
    function validatePasswordComponent(value) {
        const minMaxLength = /^[\s\S]{8,}$/,
            upper = /[A-Z]/,
            lower = /[a-z]/,
            number = /[0-9]/,
            special = /[!#$%&'_()*+,-./:;<=>?@[\]^`{|}~]/;
        return !upper.test(value)
            ? "1 uppercase must"
            : !lower.test(value)
                ? "1 lowercase must"
                : !number.test(value)
                    ? "1 integer must"
                    : !special.test(value)
                        ? "1 special char must"
                        : !minMaxLength.test(value)
                            ? "Length 8 must"
                            : "";
    }
    function validatePassword(data) {
        const minMaxLength = /^[\s\S]{8,}$/,
            upper = /[A-Z]/,
            lower = /[a-z]/,
            number = /[0-9]/,
            special = /[!#$%&'_()*+,-./:;<=>?@[\]^`{|}~]/;
        var result =
            upper.test(data.password) &&
            lower.test(data.password) &&
            number.test(data.password) &&
            special.test(data.password) &&
            minMaxLength.test(data.password);
        return !data.userId &&
            !isValidPasswordPerRoleId(data.roleId) &&
            !data.password
            ? { isValid: false, helperText: "*Required" }
            : !isValidPasswordPerRoleId(data.roleId) && data.password && !result
                ? { isValid: false, helperText: "Policy mismatch" }
                : { isValid: true, helperText: "" };
    }
    function getPasswordComponent(objProps) {
        const rowData = objProps.rowData;
        let value = rowData.password;
        if (isValidPasswordPerRoleId(rowData.roleId)) {
            value = "";
        }
        return (
            <TextField
                id="standard-basic"
                value={value}
                placeholder="Password"
                onChange={(e) => objProps.onChange(e.target.value)}
                disabled={isValidPasswordPerRoleId(rowData.roleId)}
                error={
                    !rowData.userId
                        ? !isValidPasswordPerRoleId(rowData.roleId) && !value
                        : !isValidPasswordPerRoleId(rowData.roleId) &&
                        value &&
                        validatePasswordComponent(value)
                }
                helperText={
                    !rowData.userId && !isValidPasswordPerRoleId(rowData.roleId) && !value
                        ? "*Required"
                        : !isValidPasswordPerRoleId(rowData.roleId) && value
                            ? validatePasswordComponent(value)
                            : ""
                }
            />
        );
    }
    function getRoleDDLComponent(objProps) {
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    value={objProps.value}
                    onChange={(e) => {
                        objProps.onChange(e.target.value);
                    }}
                >
                    {itemsRole.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{!objProps.value ? "*Required" : ""}</FormHelperText>
            </FormControl>
        );
    }
    function renderRoleValue(value) {
        let obj = {};
        if (value === 6) {
            return "Internal user";
        } else {
            if (itemsRole && itemsRole.length > 0) {
                obj = itemsRole.find((c) => c.value == value);
            }
            if (obj) {
                return obj.label;
            } else {
                return "";
            }
        }
    }
    function validateRole(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }
    function validateFirstName(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isValidNameField(value)
                ? { isValid: false, helperText: "Only alphabets" }
                : { isValid: true, helperText: "" };
    }
    function validateLastName(value) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : !isValidNameField(value)
                ? { isValid: false, helperText: "Only alphabets" }
                : { isValid: true, helperText: "" };
    }
    function validateEmailOrUsername(data) {
        return isValidPasswordPerRoleId(data.roleId)
            ? validateEmail(data)
            : validateUsername(data);
    }
    function validateEmail(data) {
        return !data.userEmail
            ? { isValid: false, helperText: "*Required" }
            : !isValidEmail(data.userEmail)
                ? { isValid: false, helperText: "Invalid Email" }
                : (!data.userId && isEmailExists(data.userEmail)) ||
                    (data.userId && isEmailExists(data.userEmail, data.userId))
                    ? { isValid: false, helperText: "Already exists" }
                    : { isValid: true, helperText: "" };
    }
    function isValidUsername(value) {
        const minMaxLength = /^[\s\S]{8,}$/,
            letter = /[aA-zZ]/,
            number = /[0-9]/,
            special = /[!#$%&'_()*+,-./:;<=>?@[\]^`{|}~]/;
        return !letter.test(value)
            ? { isValid: false, helperText: "1 alphabet must" }
            :
            !number.test(value)
                ? { isValid: false, helperText: "1 integer must" }
                : !special.test(value)
                    ? { isValid: false, helperText: "1 special char must" }
                    : !minMaxLength.test(value)
                        ? { isValid: false, helperText: "Length 8 must" }
                        : { isValid: true, helperText: "" };
    }
    function validateUsername(data) {
        return !data.userEmail
            ? { isValid: false, helperText: "*Required" }
            : (!data.userId && isEmailExists(data.userEmail)) ||
                (data.userId && isEmailExists(data.userEmail, data.userId))
                ? { isValid: false, helperText: "Already exists" }
                : isValidUsername(data.userEmail);
    }
    function validateGID(data) {
        return !isValidGIDPerRoleId(data.roleId) && !data.gid
            ? { isValid: false, helperText: "*Required" }
            : !isValidGIDPerRoleId(data.roleId) &&
                data.gid &&
                ((!data.userId && isGIDExists(data.gid)) ||
                    (data.userId && isGIDExists(data.gid, data.userId)))
                ? { isValid: false, helperText: "Already exists" }
                : { isValid: true, helperText: "" };
    }
    function getSupplierCodeDDLComponent(objProps) {
        const rowData = objProps.rowData;
        let value = rowData.supplierId;
        if (isValidSupplierPerRoleId(rowData.roleId)) {
            value = "";
        }
        return (
            <FormControl
                error={
                    !isValidSupplierPerRoleId(rowData.roleId) && !objProps.value
                        ? true
                        : false
                }
                fullWidth
            >
                <Select
                    disabled={isValidSupplierPerRoleId(objProps.rowData.roleId)}
                    value={value}
                    onChange={(e) => {
                        objProps.onChange(e.target.value);
                    }}
                >
                    {itemsSupplierCode.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                    {!isValidSupplierPerRoleId(rowData.roleId) && !objProps.value
                        ? "*Required"
                        : ""}
                </FormHelperText>
            </FormControl>
        );
    }
    function renderSupplierCodeValue(value) {
        let obj = {};
        if (itemsSupplierCode && itemsSupplierCode.length > 0) {
            obj = itemsSupplierCode.find((c) => c.value == value);
        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }
    function validateSupplierCode(data) {
        return !isValidSupplierPerRoleId(data.roleId) && !data.supplierId
            ? { isValid: false, helperText: "*Required" }
            : { isValid: true, helperText: "" };
    }
    var columns = [
        { title: "UserId", field: "userId", hidden: true },
        {
            title: "Role",
            field: "roleId",
            render: (rowData) => <span>{renderRoleValue(rowData.roleId)}</span>,
            editComponent: (props) => getRoleDDLComponent(props),
            validate: (rowData) => validateRole(rowData.roleId),
            customFilterAndSearch: (term, rowData) =>
                renderRoleValue(rowData.roleId)
                    .toLowerCase()
                    .includes(term.toLowerCase()),
        },
        {
            title: "First Name",
            field: "userFirstName",
            validate: (rowData) => validateFirstName(rowData.userFirstName),
        },
        {
            title: "Last Name",
            field: "userLastName",
            validate: (rowData) => validateLastName(rowData.userLastName),
        },
        {
            title: "Email Or Username",
            field: "userEmail",
            validate: (rowData) => validateEmailOrUsername(rowData),
        },
        {
            title: "GID",
            field: "gid",
            editComponent: (props) => getGIDComponent(props),
            validate: (rowData) => validateGID(rowData),
        },
        {
            title: "Supplier Code",
            field: "supplierId",
            render: (rowData) => (
                <span>{renderSupplierCodeValue(rowData.supplierId)}</span>
            ),
            editComponent: (props) => getSupplierCodeDDLComponent(props),
            validate: (rowData) => validateSupplierCode(rowData),
            customFilterAndSearch: (term, rowData) =>
                renderSupplierCodeValue(rowData.supplierId)
                    .toLowerCase()
                    .includes(term.toLowerCase()),
        },
        {
            title: "Password",
            field: "password",
            editComponent: (props) => getPasswordComponent(props),
            validate: (rowData) => validatePassword(rowData),
        },
    ];
    const [tableData, setTableData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);

    function getTableData() {
        setIsLoader(true);
        getUserData(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId
        )
            .then((response) => {
                setTableData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("Error: " + error, "error");
                setIsLoader(false);
            });
    }

    const enterKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", enterKeyDown);
        getRoleDDL();
        getSupplierCodeDDL();
        getTableData();

        return () => {
            document.removeEventListener("keydown", enterKeyDown);
        };
    }, [userdetails]);

    let [itemsRole, setItemsRole] = useState([]);
    async function getRoleDDL() {
        const response = await getRolesDDL(
            siteToken,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            UID.id,
            userdetails.roleId,
            azureToken.token
        );
        setItemsRole(
            response.map((item) => ({ value: item.id, label: item.name }))
        );
    }
    let [itemsSupplierCode, setItemsSupplierCode] = useState([]);
    async function getSupplierCodeDDL() {
        const response = await getSupplierCodesDDL(
            siteToken,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            azureToken.token
        );
        setItemsSupplierCode(
            response.map((item) => ({ value: item.id, label: item.name }))
        );
    }
    const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {
        setIsLoader(true);
        if (isValidGIDPerRoleId(newTableData.roleId)) {
            newTableData.gid = "";
        }
        if (isValidSupplierPerRoleId(newTableData.roleId)) {
            newTableData.supplierId = null;
        }

        newTableData.loggedinUserId = userdetails.userId;
        updateUserData(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            newTableData

        )
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowAdd = (newTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.loggedinUserId = userdetails.userId;
        addUserData(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            newTableData

        )
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowDelete = (oldTableData, resolve, reject) => {
        setIsLoader(true);
        oldTableData.loggedinUserId = userdetails.userId;
        deleteUserData(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            oldTableData
            
        )
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    function getPasswordPolicyAsTooltipTitle() {
        return (
            <div className="f-12 clr-white">
                <ListItemText primary="1. Password must be of atleast 8 characters" />
                <ListItemText primary="2. Password must contains atleast 1 capital letter" />
                <ListItemText primary="3. Password must contains atleast 1 number" />
                <ListItemText primary="4. Password must contains atleast 1 special character(e.g. !#$%&'()*+,-./:;<=>?@[\]^`{|}~)" />
            </div>
        );
    }
    return (
        <div>
            <div className="App">
                <Grid container spacing={1}>
                    <Grid item xs={12} className="d-flex jc-space-bt">
                        <Grid item xs={9} className="text-left ml-1">
                            <h2>User List</h2>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            className="text-right"
                        >
                            <Typography className="policy-header">
                                <Tooltip
                                    title={getPasswordPolicyAsTooltipTitle()}
                                    placement="bottom"
                                >
                                    <span className="f-14">
                                        <InfoIcon
                                            style={{
                                                color: "var(--blue-green)",
                                                marginBottom: "-15px",
                                                marginRight: "-10px",
                                            }}
                                        />
                                        <strong>Password Policy</strong>
                                    </span>
                                </Tooltip>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <MaterialTable
                            title="User List"
                            columns={columns}
                            data={tableData}
                            icons={tableIcons}
                            isLoading={isLoader}
                            options={tableOptions}
                            editable={{
                                isEditable: (rowData) => rowData.roleId !== 6,
                                isDeletable: (rowData) => rowData.roleId !== 6,
                                onRowUpdate: (newTableData, oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowUpdate(
                                            newTableData,
                                            oldTableData,
                                            resolve,
                                            reject
                                        );
                                    }),
                                onRowAdd: (newTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowAdd(newTableData, resolve, reject);
                                    }),
                                onRowDelete: (oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowDelete(oldTableData, resolve, reject);
                                    }),
                            }}
                        />
                    </Grid>
                </Grid>
                <>
                    <SnackbarCustom
                        open={open}
                        message={snackMessage}
                        alertType={alertType}
                        handleClose={handleClose}
                    />
                </>
            </div>
        </div>
    );
}
