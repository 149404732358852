import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";
import { makeStyles } from "@material-ui/core/styles";
import { apiKeys, baseApiURL } from "../../Utilities/utility";
import {
    Container,
    Box,
    Grid,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    AppBar,
    Tabs,
    Tab,
    Typography,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import "./Login.scss";
import SiemensLogo from "../../assets/images/siemens-logo-petrol.svg";
import {
    validateTheUserBasedOnApi,
    authenticateExternalUser,
    authenticateContractUser,
    validateReqExtUser,
} from "../../Redux/API/api_Login";
import {
    storeUserDetailsAction,
    isVerifiedOTPAction,
    isForgotPasswordAction,
    custHeaderAction,
    saveAccessTokenAction,
} from "../../Redux/Actions/actions";
import Timer from "../Components/CountdownTimer/Timer";
import Loading from "../Components/Loading/Loading";
import { forgotUserPassword } from "../../Redux/API/api_UserManagement";
import { generateTokenOnly } from "../../Redux/API/api_Login";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { spocEmail } from "../../Utilities/utility";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "rgba(0, 204, 204, .05)",
        backdropFilter: "blur(3px)",
        minHeight: "100vh",
        overflow: "auto",
    },
    paper: {
        padding: theme.spacing(2),
    },
    errorMessage: {
        color: "var(--red)",
        fontSize: "16px",
    },
    timerDetails: {
        backgroundColor: "var(--bg-green-2)",
    },
    forgotPwd: {
        textAlign: "center",
        textDecoration: "underline",
    },
    loginTabs: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "15px",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: "0px",
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function Login() {
    const auth = useAuth();
    const classes = useStyles();
    const dispatch = useDispatch();
    const siteToken = useSelector((state) => state.saveTokenReducer);

    const errMessage = useSelector((state) => state.errorMessageReducer);
    const { control, handleSubmit } = useForm();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState("");
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userDataTemp, setUserDataTemp] = useState({});
    const [expiredTime, setExpiredTime] = useState(Date.now());
    const [isLoader, setIsLoader] = useState(false);

    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const [emailHelper, setEmailHelper] = useState("");
    const [otpHelper, setOtpHelper] = useState(null);
    const [pwdDialogOpen, setPwdDialogOpen] = useState(false);
    const [forgotPwdEmail, setForgotPwdEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [enterOTPDialogOpen, setEnterOTPDialogOpen] = useState(false);
    const [otpValue, setOtpValue] = useState("");
    const [isOtpValid, setIsOtpValid] = useState(false);

    const [usernameContract, setUsernameContract] = useState("");
    const [passwordContract, setPasswordContract] = useState("");
    const [emailHelperContract, setEmailHelperContract] = useState("");
    const [passwordHelperContract, setPasswordHelperContract] = useState(null);

    const { instance } = useMsal();

    const handleDialogOpen = (content, title) => {
        setDialogTitle(title);
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handlePwdDialogOpen = () => {
        setPwdDialogOpen(true);
        setIsForgotPassword(true);
    };
    const handlePwdDialogClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setForgotPwdEmail("");
        setEmailHelper("");
        setPwdDialogOpen(false);
    };

    const handleOTPDialogOpen = () => {
        setEnterOTPDialogOpen(true);
    };
    const handleOTPDialogClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        setEnterOTPDialogOpen(false);
    };

    const sendForgotPwdOTP = () => {
        if (isEmailValid) {
            forgotPassword();
        } else {
            setIsEmailValid(false);
            setEmailHelper("Email is mandatory");
        }
    };

    async function forgotPassword() {
        const userData = {
            azureKeyVault: forgotPwdEmail,
        };
        setIsLoader(true);

        generateTokenOnly(dispatch, forgotPwdEmail)
            .then((tokResponse) => {
                const genToken = tokResponse;
                forgotUserPassword(genToken, userData)
                    .then((responseAuthenticate) => {
                        if (responseAuthenticate) {
                            if (typeof responseAuthenticate === "object") {
                                setUserDataTemp(responseAuthenticate);
                                setIsLoader(false);
                                setEnterOTPDialogOpen(true);
                            } else {
                                if (typeof responseAuthenticate === "string") {
                                    setIsLoader(false);
                                    handleDialogOpen(
                                        responseAuthenticate,
                                        responseAuthenticate.includes("Failed") ? "Error" : "Alert"
                                    );
                                } else {
                                    setIsLoader(false);
                                    handleDialogOpen(
                                        "Invalid user data returned from server",
                                        "Error"
                                    );
                                }
                            }
                        } else {
                            setIsLoader(false);
                            handleDialogOpen("Invalid email or password", "Error");
                        }
                    })
                    .catch((error) => {
                        setIsLoader(false);
                        handleDialogOpen("Error in external login", "Error");
                    });
            })
            .catch((error) => {
                handleDialogOpen("Error: " + error, "error");
            });
    }

    const handleClose = () => {
        setDialogOpen(false);
        setPwdDialogOpen(false);
    };

    const handleForgotPwdChange = (event) => {
        let emailValid;
        setForgotPwdEmail(event.target.value);
        emailValid =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                event.target.value
            );
        if (!emailValid) {
            setIsEmailValid(false);
            setEmailHelper("Invalid Email");
        } else {
            setIsEmailValid(true);
            setEmailHelper("");
        }
    };

    function isValidEmail(value) {
        const exp =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return exp.test(value);
    }
    function isValidUsername(value) {
        const minMaxLength = /^[\s\S]{8,}$/,
            letter = /[aA-zZ]/,
            number = /[0-9]/,
            special = /[!#$%&'_()*+,-./:;<=>?@[\]^`{|}~]/;

        return (
            letter.test(value) &&
            number.test(value) &&
            special.test(value) &&
            minMaxLength.test(value)
        );
    }
    function validateUsername(value) {
        if (!value) {
            setEmailHelperContract("*Required");
        } else {
            !isValidUsername(value)
                ? setEmailHelperContract("Invalid Username")
                : setEmailHelperContract(null);
        }
    }
    function validatePassword(value) {
        if (!value) {
            setPasswordHelperContract("*Required");
        } else {
            setPasswordHelperContract(null);
        }
    }
    const handleChangeContractUserName = (e) => {
        setUsernameContract(e.target.value);
        validateUsername(e.target.value);
    };
    const handleChangeContractPassword = (e) => {
        setPasswordContract(e.target.value);
        validatePassword(e.target.value);
    };
    const handleClickContractLogin = (e) => {
        let loginData = {
            AzureKeyVault: usernameContract,
            KeyVaultValue: passwordContract,
        };
        contractUserLogin(loginData);
    };
    function contractUserLogin(loginData) {
        setIsLoader(true);
        apiKeys.AzureKeyVault = loginData.AzureKeyVault;
        apiKeys.KeyVaultValue = loginData.KeyVaultValue;
        const gid = "";

        // validateTheUserBasedOnApi(dispatch, auth, gid)
        //     .then((responseValidate) => {
        //         if (responseValidate.response) {
        //             authenticateContractUser(responseValidate.objResult.token, loginData)
        //                 .then((responseAuthenticate) => {
        //                     if (responseAuthenticate) {
        //                         if (typeof responseAuthenticate === "object") {
        //                             let userData = {
        //                                 gid: responseAuthenticate.gid,
        //                                 roleId: responseAuthenticate.roleId,
        //                                 roleName: responseAuthenticate.roleName,
        //                                 userEmail: responseAuthenticate.userEmail,
        //                                 userFirstName: responseAuthenticate.userFirstName,
        //                                 userLastName: responseAuthenticate.userLastName,
        //                                 userId: responseAuthenticate.userId,
        //                                 supplierId: responseAuthenticate.supplierId,
        //                             };
        //                             dispatch(storeUserDetailsAction(userData));
        //                             setIsLoader(false);
        //                         } else {
        //                             if (typeof responseAuthenticate === "string") {
        //                                 setIsLoader(false);
        //                                 handleDialogOpen(
        //                                     responseAuthenticate,
        //                                     responseAuthenticate.includes("Failed")
        //                                         ? "Error"
        //                                         : "Alert"
        //                                 );
        //                             } else {
        //                                 setIsLoader(false);
        //                                 handleDialogOpen(
        //                                     "Invalid user data returned from server",
        //                                     "Error"
        //                                 );
        //                             }
        //                         }
        //                     } else {
        //                         setIsLoader(false);
        //                         handleDialogOpen("Invalid email or password", "Error");
        //                     }
        //                 })
        //                 .catch((error) => {
        //                     setIsLoader(false);
        //                     handleDialogOpen("Error in external login", "Error");
        //                 });
        //         }
        //     })
        //     .catch((error) => {
        //         handleDialogOpen("Error in token generation", "Error");
        //     });

        validateReqExtUser(apiKeys)
            .then((response) => {
                authenticateContractUser(dispatch, response.token, apiKeys)
                    .then((responseAuthenticate) => {
                        if (responseAuthenticate) {
                            if (typeof responseAuthenticate === "object") {
                                let userData = {
                                    gid: responseAuthenticate.response.gid,
                                    roleId: responseAuthenticate.response.roleId,
                                    roleName: responseAuthenticate.response.roleName,
                                    userEmail: responseAuthenticate.response.userEmail,
                                    userFirstName: responseAuthenticate.response.userFirstName,
                                    userLastName: responseAuthenticate.response.userLastName,
                                    userId: responseAuthenticate.response.userId,
                                    supplierId: responseAuthenticate.response.supplierId,
                                };
                                dispatch(storeUserDetailsAction(userData));
                                dispatch(saveAccessTokenAction(responseAuthenticate.tokenString));
                                setIsLoader(false);
                            } else {
                                if (typeof responseAuthenticate === "string") {
                                    setIsLoader(false);
                                    handleDialogOpen(
                                        responseAuthenticate,
                                        responseAuthenticate.includes("Failed")
                                            ? "Error"
                                            : "Alert"
                                    );
                                } else {
                                    setIsLoader(false);
                                    handleDialogOpen(
                                        "Invalid user data returned from server",
                                        "Error"
                                    );
                                }
                            }
                        } else {
                            setIsLoader(false);
                            handleDialogOpen("Invalid email or password", "Error");
                        }
                    })
                    .catch((error) => {
                        setIsLoader(false);
                        handleDialogOpen("Error in external login", "Error");
                    });

            })
            .catch((error) => {
                handleDialogOpen("Error in token generation", "Error");
            });
    }

    function myIDLogin() {
        dispatch(auth.signinRedirect);
    }

    function externalUserLogin(userData) {
        setIsLoader(true);
        setEmail(userData.AzureKeyVault);
        setPassword(userData.KeyVaultValue);
        apiKeys.AzureKeyVault = userData.AzureKeyVault;
        apiKeys.KeyVaultValue = userData.KeyVaultValue;
        const gid = "";
        validateTheUserBasedOnApi(dispatch, auth, gid)
            .then((responseValidate) => {
                if (responseValidate.response) {
                    authenticateExternalUser(responseValidate.objResult.token, userData)
                        .then((responseAuthenticate) => {
                            if (responseAuthenticate) {
                                if (typeof responseAuthenticate === "object") {
                                    setUserDataTemp(responseAuthenticate);
                                    setIsLoader(false);
                                    setEnterOTPDialogOpen(true);
                                } else {
                                    if (typeof responseAuthenticate === "string") {
                                        setIsLoader(false);
                                        handleDialogOpen(
                                            responseAuthenticate,
                                            responseAuthenticate.includes("Failed")
                                                ? "Error"
                                                : "Alert"
                                        );
                                    } else {
                                        setIsLoader(false);
                                        handleDialogOpen(
                                            "Invalid user data returned from server",
                                            "Error"
                                        );
                                    }
                                }
                            } else {
                                setIsLoader(false);
                                handleDialogOpen("Invalid email or password", "Error");
                            }
                        })
                        .catch((error) => {
                            setIsLoader(false);
                            handleDialogOpen("Error in external login", "Error");
                        });
                }
            })
            .catch((error) => {
                handleDialogOpen("Error in token generation", "Error");
            });
    }

    function VerifyOTP() {
        const options = {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Access-Control-Allow-Credentials": true,
                // Authorization: "Bearer " + siteToken.token,
            },
        };

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ',
        };
        const token = siteToken.token;
        if (otpValue != "") {
            return fetch(baseApiURL + "/Login/ValidateRequestInput?otp=" + otpValue + "&token=" + token, options)
                //return fetch(baseApiURL + "/Login/ValidateRequestInput?otp=" + otpValue, options)
                .then(response => response.json())
                .then(data => {
                    if (data.token != "" && data.token != undefined) {
                        let userData = {
                            gid: userDataTemp.gid,
                            roleId: userDataTemp.roleId,
                            roleName: userDataTemp.roleName,
                            userEmail: userDataTemp.userEmail,
                            userFirstName: userDataTemp.userFirstName,
                            userLastName: userDataTemp.userLastName,
                            userId: userDataTemp.userId,
                            supplierId: userDataTemp.supplierId,
                            changePassword: userDataTemp.changePassword,
                            passwordChangedOn: userDataTemp.passwordChangedOn,
                        };
                        dispatch(saveAccessTokenAction(data.token));
                        dispatch(storeUserDetailsAction(userData));
                        dispatch(isVerifiedOTPAction(true));
                    }
                    else {
                        handleDialogOpen("OTP did not match, try again");
                    }
                }).catch(error => {
                    handleDialogOpen("OTP did not match, try again");
                });
        }
        else {
            handleDialogOpen("OTP did not match, try again");
        }
    }


    const ResendOTP = (e) => {
        handleClose.call();
        let userData = {
            AzureKeyVault: email,
            KeyVaultValue: password,
        };
        externalUserLogin(userData);
    };
    const ResendForgotPwdOTP = () => {
        handleOTPDialogClose();
        forgotPassword();
    };

    function VerifyForgotPwdOTP() {
        let userData = {
            userEmail: userDataTemp.userEmail,
        };
        dispatch(storeUserDetailsAction(userData));
        dispatch(custHeaderAction("CustomizedHeader"));
        dispatch(isVerifiedOTPAction(true));
        dispatch(isForgotPasswordAction(true));
    }

    function handleChangeOtp(event) {
        validateOTP(event.target.value);
        setOtpValue(event.target.value);
    }

    function validateOTP(value) {
        if (!value) {
            setOtpHelper("OTP is mandatory");
        } else {
            const re = /^\d*$/;
            if (!re.test(value)) {
                setOtpHelper("OTP must be integer");
                setIsOtpValid(false);
            } else if (value.toString().length !== 6) {
                setOtpHelper("OTP must be of 6 digit");
                setIsOtpValid(false);
            }
            else {
                setOtpHelper(null);
                setIsOtpValid(true);
            }
        }
    }

    useEffect(() => {
        if (enterOTPDialogOpen) {
            setExpiredTime(Date.now() + 180000);
            setTimeout(function () {
                handleOTPDialogClose();
            }, 180000);
        }
    }, [enterOTPDialogOpen]);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function azureADLogin() {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
    }

    return (
        <div className="bg-login-register">
            <div className={classes.root}>
                <Container>
                    <Grid container className="pt-lr-mobileContainer">
                        <Grid item xs={12}>
                            <div className="pt-login-logo-block">
                                <img
                                    src={SiemensLogo}
                                    className="official-logo"
                                    alt="Siemens"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={10} md={6} className="pt-lr--project-block">
                            <div className="pt-lr--project">
                                <p className="pt-lr-project-name">
                                    POTTS - Production Order Track & Trace System
                                </p>
                                <span className="pt-lr--project-description">
                                    Blend of diverse business tools along with reporting based on
                                    SAP data
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={10} md={6} className="pt-lr-master-block">
                            <div className={classes.loginTabs}>
                                <AppBar position="static">
                                    <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="simple tabs example"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Tab
                                            className="pl-tab-2i"
                                            label="Azure AD Users"
                                            {...a11yProps(0)}
                                        />
                                        <Tab label="External Supplier" {...a11yProps(1)} />
                                        <Tab
                                            className="pr-tab-2i"
                                            label="Stage Users"
                                            {...a11yProps(2)}
                                        />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={value} index={0}>
                                    <div className="pt-login-siemens_user">
                                        <h3 className="heading-margin-5tb-0lr">
                                            Azure AD Users Login{" "}
                                        </h3>
                                        <p>If you are a Siemens Employee, login via Azure AD</p>
                                        <div className="d-flex jc-center myid-login-btn-block">
                                            <Link to="/">
                                                {/* <Button
                                                    className="pt-button--hero myid-login-btn"
                                                    id="myIDLoginBtn"
                                                    onClick={myIDLogin}
                                                >
                                                    <span>MyID Login</span>
                                                </Button> */}
                                                <Button
                                                    className="pt-button--hero myid-login-btn"
                                                    id="AzureAdLogin"
                                                    onClick={azureADLogin}
                                                >
                                                    <span>AD Login</span>
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <div className="pt-login-external_user">
                                        <h3 className="heading-margin-5tb-0lr">Supplier Login</h3>
                                        <span>
                                            If you are an external supplier, login via Azure AD
                                        </span>
                                        <p className={classes.errorMessage}>{errMessage}</p>
                                        {/* <form
                                            noValidate
                                            autoComplete="off"
                                            className="pt-login-form"
                                            // onSubmit={handleSubmit((data) => externalUserLogin(data))}
                                            onSubmit={azureADLogin}
                                        >
                                            <Controller
                                                name="AzureKeyVault"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: "Email ID required",
                                                    pattern: {
                                                        value:
                                                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                        message: "Invalid email format",
                                                    },
                                                }}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        fullWidth
                                                        type="email"
                                                        style={{display: "none"}}
                                                        label="Email"
                                                        variant="filled"
                                                        value={value}
                                                        onChange={onChange}
                                                        error={!!error}
                                                        helperText={error ? error.message : null}
                                                        id="supplierEmail"
                                                        className="pt-login-form-input mb-1"
                                                    />
                                                )}
                                            />
                                            <Controller
                                                name="KeyVaultValue"
                                                control={control}
                                                defaultValue=""
                                                rules={{
                                                    required: "Password required",
                                                    minLength: {
                                                        value: 6,
                                                        message:
                                                            "Password must be greater than 6 characters",
                                                    },
                                                    maxLength: {
                                                        value: 15,
                                                        message:
                                                            "Password cannot be greater than 15 characters",
                                                    },
                                                }}
                                                render={({
                                                    field: { onChange, value },
                                                    fieldState: { error },
                                                }) => (
                                                    <TextField
                                                        fullWidth
                                                        type="password"
                                                        style={{display: "none"}}
                                                        label="Password"
                                                        variant="filled"
                                                        value={value}
                                                        onChange={onChange}
                                                        error={!!error}
                                                        helperText={error ? error.message : null}
                                                        id="supplierPassword"
                                                        className="pt-login-form-input mb-1"
                                                    />
                                                )}
                                            />

                                            <div className="d-flex jc-center supplier-login-btn-block">
                                                <Button
                                                    type="submit"
                                                    className="pt-button--hero supplier-login-btn"
                                                    id="SupplierLoginBtn"
                                                >
                                                    AD Login
                                                </Button>
                                            </div>
                                        </form> */}

                                        <div className="d-flex jc-center myid-login-btn-block">
                                            <Link to="/">
                                                {/* <Button
                                                    className="pt-button--hero myid-login-btn"
                                                    id="myIDLoginBtn"
                                                    onClick={myIDLogin}
                                                >
                                                    <span>MyID Login</span>
                                                </Button> */}
                                                <Button
                                                    className="pt-button--hero myid-login-btn"
                                                    id="AzureAdLogin"
                                                    onClick={azureADLogin}
                                                >
                                                    <span>AD Login</span>
                                                </Button>
                                            </Link>
                                        </div>

                                    </div>

                                    <div className={classes.forgotPwd} style={{ display: "none" }}>
                                        <h5
                                            className="forget-pwd-cursor"
                                            onClick={handlePwdDialogOpen}
                                        >
                                            Forgot Password
                                        </h5>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <div className="pt-login-external_user">
                                        <h3 className="heading-margin-5tb-0lr">
                                            Stage Users Login
                                        </h3>
                                        <span>
                                            If you are a contractual employee, login via username and
                                            password
                                        </span>
                                        <p className={classes.errorMessage}>{errMessage}</p>
                                        <TextField
                                            fullWidth
                                            type="email"
                                            label="Username"
                                            variant="filled"
                                            value={usernameContract}
                                            onChange={handleChangeContractUserName}
                                            error={
                                                emailHelperContract && emailHelperContract.length > 0
                                            }
                                            helperText={emailHelperContract}
                                            id="contractEmployeeUsername"
                                            className="pt-login-form-input mb-1"
                                        />
                                        <TextField
                                            fullWidth
                                            type="password"
                                            label="Password"
                                            variant="filled"
                                            value={passwordContract}
                                            onChange={handleChangeContractPassword}
                                            error={
                                                passwordHelperContract &&
                                                passwordHelperContract.length > 0
                                            }
                                            helperText={passwordHelperContract}
                                            id="contractEmployeePassword"
                                            className="pt-login-form-input mb-1"
                                        />

                                        <div className="d-flex jc-center supplier-login-btn-block">
                                            <Button
                                                type="button"
                                                className="pt-button--hero supplier-login-btn"
                                                id="contractEmployeeLoginBtn"
                                                onClick={handleClickContractLogin}
                                                disabled={
                                                    !(usernameContract && passwordContract) ||
                                                    emailHelperContract ||
                                                    passwordHelperContract
                                                }
                                            >
                                                Login
                                            </Button>
                                        </div>
                                    </div>
                                </TabPanel>
                                <div className="d-flex jc-center">
                                    <span>Having Issues?&nbsp;</span>
                                    <span>
                                        <a className="contact-us-text" href={"mailto:" + spocEmail}>
                                            Contact us
                                        </a>
                                    </span>
                                </div>
                            </div>

                        </Grid>
                    </Grid>

                    {/* Change Password */}
                    <Dialog
                        open={pwdDialogOpen}
                        onClose={handlePwdDialogClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">Forgot Password</DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus
                                variant="filled"
                                id="forgotEmail"
                                label="Enter Registered Email ID"
                                type="email"
                                onChange={handleForgotPwdChange}
                                value={forgotPwdEmail}
                                error={emailHelper.length !== 0}
                                helperText={emailHelper}
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handlePwdDialogClose}
                                className="pt-button--secondary"
                            >
                                Cancel
                            </Button>
                            <Button onClick={sendForgotPwdOTP} className="pt-button--primary">
                                Send OTP
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* verify OTP for Forgot Password */}
                    <div>
                        <Dialog
                            open={enterOTPDialogOpen}
                            onClose={handleOTPDialogClose}
                            aria-labelledby="otp-dialog-title"
                            aria-describedby="otp-dialog-description"
                        >
                            <div>
                                <DialogTitle id="otp-dialog-title">Enter OTP</DialogTitle>
                                <IconButton
                                    aria-label="close"
                                    className={classes.closeButton}
                                    onClick={handleOTPDialogClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <DialogContent>
                                <DialogContentText id="otp-dialog-description">
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        type="text"
                                        label="Enter OTP"
                                        variant="filled"
                                        value={otpValue}
                                        inputProps={{ maxLength: 6 }}
                                        onChange={handleChangeOtp}
                                        error={otpHelper && otpHelper.length > 0}
                                        helperText={otpHelper}
                                        id="forgotPwdOTP"
                                    />

                                    <Box>
                                        <span>OTP will be expired in</span>
                                        <Timer
                                            expiredTime={expiredTime}
                                            className={classes.timerDetails}
                                        />
                                    </Box>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={isForgotPassword ? ResendForgotPwdOTP : ResendOTP}
                                    type="input"
                                    className="pt-button--secondary supplier-login-btn"
                                    id="btnOtpResend"
                                >
                                    Resend
                                </Button>
                                <Button
                                    onClick={isForgotPassword ? VerifyForgotPwdOTP : VerifyOTP}
                                    className="pt-button--primary supplier-login-btn"
                                    id="btnOtpVerify"
                                    disabled={otpHelper && otpHelper.length > 0 && !otpValue}
                                >
                                    Verify
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>

                    <div>
                        <Dialog
                            open={dialogOpen}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {dialogContent}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    type="input"
                                    className="pt-button--secondary supplier-login-btn"
                                    id="SupplierOTPResend"
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Container>
                {isLoader ? <Loading /> : <></>}
            </div>
        </div>
    );
}
