import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Button } from "@material-ui/core";
import { getStageDetails } from "../../../Redux/API/api_Stages";
import { PDReject } from "../../../Redux/API/api_Stages";
import { useSelector } from "react-redux";
import { baseApiURL } from "../../../Utilities/utility";
import {
  tableOptions,
} from "../../Components/CommonComponents/common_components";
import { useHistory } from "react-router-dom";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";

export default function StagePD() {
  const userdetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const [pdData, setPdData] = useState([]);
    const [tableLoader, setTableLoader] = useState(true);
    const apiURL = baseApiURL + "/ProdOrder/GetPDPos";
    const siteToken = useSelector((state) => state.saveTokenReducer);
    let history = useHistory();

    function apiReject(data) {
      setTableLoader(true);
        const rejectURL = baseApiURL + "/ProdOrder/PDReject?userId=" + UID.id;
      const apiData = {
        productionOrderNumber: data.productionOrderNumber,
        productionOrderDetailsId: data.productionOrderId,
        loggedInUserId: userdetails.userId,
      };
      PDReject(
        rejectURL,
        apiData,
          siteToken,
        userdetails.userEmail,
        userdetails.roleName,
        userdetails.gid,
        userdetails.roleId,
        azureToken.token
      )
        .then((response) => {
          setTableLoader(false);
          handleSnackOpen(
            response.result.responseMsg,
            response.result.response ? "success" : "error"
          );
          getTableData();
        })
        .catch((error) => {
          handleSnackOpen("Error : " + error, "error");
        });
    }

    const columns = [
      {
        title: "Scan PO",
        field: "scanPO",
        render: (rowData) => scanProdOrder(rowData),
      },
      {
        title: "Production Order",
        field: "productionOrderNumber",
      },
      { title: "Pole Count", field: "poleCount" },
      { title: "QR Paper Count", field: "paperCount" },
      {
        title: "Reject",
        field: "reject",
        render: (rowData) => (
          <Button
            className="pt-button--delete"
            onClick={() => apiReject(rowData)}>
            Reject
          </Button>
        ),
      },
    ];

    function scanProdOrder(data) {
      return (
        <Button
          className="pt-button--tertiary"
          onClick={() => {
            history.push({
              pathname: "/stagePDScanner",
              state: {
                rowData: data,
              },
            });
          }}>
          Scan PO
        </Button>
      );
    }
    function getTableData() {
      setTableLoader(true);
      getStageDetails(
        apiURL,
        siteToken,
        UID.id,
        userdetails.userEmail,
        userdetails.gid,
        userdetails.roleName,
        userdetails.roleId,
        azureToken.token
      )
        .then((response) => {
          setPdData(response.objResult ? response.objResult : []);
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          handleSnackOpen("Error : " + error, "error");
        });
    }

  useEffect(() => {
    getTableData();
  }, [userdetails]);

  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };

  return (
    <>
      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
      <div>
        <h2>PD</h2>
        <MaterialTable
          columns={columns}
          data={pdData}
          isLoading={tableLoader}
          options={tableOptions}
        />
      </div>
    </>
  );
}
