import React from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import SuccessCircleTick from "../../../../src/assets/images/SuccessCircleTick.png";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function ProductionOrderDispatched() {
  const history = useHistory();
  const location = useLocation();
  const poNumber = location.state.prodOrderNUmber;
  return (
    <Grid container justifyContent="center" maxwidth={"lg"}>
      <div className="gridCenter">
        <Paper className="po-success-block">
          <img src={SuccessCircleTick} alt="PO success" className="success-po" />
          <h3>
            Your Production Order {poNumber} has been dispatched successfully.
          </h3>
          <h4>
            To Track Production Order Workflow, click on the following button.
          </h4>
          <div className="ackFooter">
            <Button
              className="pt-button--secondary"
              onClick={() => {
                history.push({
                  pathname: "/ackorder",
                });
              }}
            >
              Go To PO Acknowledgement
            </Button>

            <Button className="pt-button--secondary" onClick={() => {
              history.push({
                pathname: "/dispatchdetails",
              });
            }}>
              Go To Dispatch Details
            </Button>
          </div>
        </Paper>
      </div>
    </Grid>
  );
}
