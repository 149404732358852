import {
  authUserAction,
  storeUserDetailsAction,
  saveAccessTokenAction,
  errorMessageAction,
  saveUserIdAction,
} from "../Actions/actions";
import { apiKeys, baseApiURL } from "../../Utilities/utility";
//import { saveAccessTokenAction } from "./../Actions/actions";
let gID,
  generatedToken = "";

export function authenticateUserBasedOnValidToken(dispatch, newToken, gID) {
  var formdataPart1 = new FormData();
  formdataPart1.append("Token", newToken);

  const options = {
    method: "post",
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formdataPart1,
  };

  return fetch(baseApiURL + "/Login/AuthenticateGId", options)
    .then((response) => response.json())
    .then((data) => {
      if (typeof data.result.response == "string") {
        var userDataNull = {
          userData: {
            gid: "",
            roleId: 0,
            roleName: "",
            userEmail: "",
            userFirstName: "",
            userLastName: "",
            userId: 0,
          },
        };
        dispatch(storeUserDetailsAction(userDataNull));
        return true;
      } else if (data.result.response.roleId != null) {
        dispatch(saveUserIdAction(data.result.uid));
        dispatch(storeUserDetailsAction(data.result.response));
        dispatch(saveAccessTokenAction(data.result.tokenString));
        window.localStorage.setItem(
          "response",
          JSON.stringify(data.result.response)
        );
        return true;
      } else if (data?.result?.response?.roleId == null) {
        var userDataNull = {
          gid: null,
          roleId: null,
          roleName: null,
          userEmail: null,
          userFirstName: null,
          userLastName: null,
          userId: null,
        };
        dispatch(storeUserDetailsAction(userDataNull));
        return false;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}

export function validateTheUserBasedOnApi(dispatch, auth, gid) {
  apiKeys.gid = gid;
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": `${window.location.origin}`,
    },
    body: JSON.stringify(apiKeys),
  };

  return fetch(baseApiURL + "/Login/AuthenticateGId", options)
    .then((res) => res.json())
    .then((data) => {
      generatedToken = data?.result?.objResult?.token;
      dispatch(saveAccessTokenAction(generatedToken));

      return data?.result;
    })
    .catch((error) => dispatch(errorMessageAction(error)));
}

export function encryptGID(dispatch, gid) {
  apiKeys.gid = gid;
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": `${window.location.origin}`,
    },
  };

  return fetch(baseApiURL + "/Login/ValidateRequestGidEncrypt?Gid=" + gid, options)
    .then((res) => res.json())
    .then((res) => {
      if (res.result.response) {
        const respMsg = res.result.responseMsg;
        dispatch(authUserAction(respMsg));
        return respMsg;
      }
      else {
        return "";
      }
    })
    .catch((error) => {
      console.log("errorMessageAction", error);
      return error;
    }
    );
}

export function generateTokenOnly(dispatch, forgotPwdEmail) {
  apiKeys.AzureKeyVault = forgotPwdEmail;
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": `${window.location.origin}`,
    },
    body: JSON.stringify(apiKeys),
  };

  return fetch(baseApiURL + "/Login/AuthenticateGId", options)
    .then((res) => res.json())
    .then((data) => {
      generatedToken = data?.result?.objResult?.token;
      dispatch(saveAccessTokenAction(generatedToken));
      return generatedToken;
    })
    .catch((error) => {
      return error;
    });
}

export function authenticateExternalUser(newToken, userData) {
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      Authorization: "Bearer " + newToken,
      "Access-Control-Allow-Origin": `${window.location.origin}`,
    },
    body: JSON.stringify(userData),
  };

  return fetch(baseApiURL + "/Login/AuthenticateExternalUser", options)
    .then((response) => response.json())
    .then((data) => {
      if (data.result) {
        return data.result;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}
export function authenticateContractUser(dispatch, newToken, loginData) {
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      Authorization: "Bearer " + newToken,
      "Access-Control-Allow-Origin": `${window.location.origin}`,
    },
    body: JSON.stringify(loginData),
  };

  return fetch(baseApiURL + "/Login/AuthenticateContractUser", options)
    .then((response) => response.json())
    .then((data) => {
      if (data.result) {
        dispatch(saveUserIdAction(data.result.uid));
        return data.result;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
}


export function validateReqExtUser(extUserData) {
  extUserData.gid = "";
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": `${window.location.origin}`,
    },
    body: JSON.stringify(extUserData)
  };

  return fetch(baseApiURL + "/Login/ValidateRequest", options)
    .then((res) => res.json())
    .then((res) => {
      if (res.result.response) {
        const respMsg = res.result.objResult;
        // dispatch(authUserAction(respMsg));
        return respMsg;
      }
      else {
        return "";
      }
    })
    .catch((error) => {
      return error;
    }
    );
}