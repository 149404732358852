import { baseApiURL } from "../../Utilities/utility";
export function getUserData(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId
) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(
        baseApiURL + "/Admin/GetUserDetails?userId=" + encodeURIComponent(userId),
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function addUserData(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data
) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostUserDetails?userId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateUserData(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data


) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutUserDetails?userId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteUserData(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data
    
) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteUserDetails?userId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function forgotUserPassword(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Login/ForgotPassword", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function ResetUserPassword(siteToken, data) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Login/ChangePassword", options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getDynamicHomeMenus(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId
) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(
        baseApiURL + "/Admin/GetLandingMenuData?userId=" + userId,
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}