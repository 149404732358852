import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import { tableIcons } from "../../../../Utilities/utility";
import {
    tableOptions,
    tableCellStyleMLFB,
    tableHeaderStyleMLFB,
    tableCellStyleLeadTime,
    tableCellStyleMLFBInLeadTime,
} from "../../../Components/CommonComponents/common_components";
import {
    getLeadMasterData,
    addLeadMasterData,
    deleteLeadMasterData,
    updateLeadMasterData,
} from "../../../../Redux/API/api_LeadTimeMaster";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import {
    FormControl,
    FormHelperText,
    TextField,
} from "@material-ui/core";
import Select from "react-select";
import { getMLFBsDDL } from "../../../../Redux/API/api_Common";

export default function LeadTimeMaster(props) {
    const UID = useSelector((state) => state.saveUserIdReducer);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    function isInteger(value) {
        const re = /^\d*$/;
        return re.test(value);
    }
    function isMLFBExists(value) {
        const obj = tableData.find((c) => c.mlfbdetailsId == value);
        return obj ? true : false;
    }
    function isMLFBExists(value, id) {
        const obj = tableData.find((c) => c.mlfbdetailsId == value);
        return obj && obj.leadTimeMasterId !== id ? true : false;
    }
    function validateAllAsRequiredAndInteger(value) {
        return value === null || value === undefined || value === ""
            ? { isValid: false, helperText: "*Required" }
            : !isInteger(value)
                ? { isValid: false, helperText: "Only integers" }
                : { isValid: true, helperText: "" };
    }
    function validateMLFB(value, id) {
        return !value
            ? { isValid: false, helperText: "*Required" }
            : (!id &&
                isMLFBExists(typeof value === "number" ? value : value.value)) ||
                (id &&
                    isMLFBExists(typeof value === "number" ? value : value.value, id))
                ? { isValid: false, helperText: "Already exists" }
                : { isValid: true, helperText: "" };
    }

    var columns = [
        {
            title: "LeadTime Master Id",
            field: "leadTimeMasterId",
            hidden: true,
        },
        {
            title: "MLFB",
            field: "mlfbdetailsId",
            cellStyle: tableCellStyleMLFBInLeadTime,
            headerStyle: tableHeaderStyleMLFB,
            render: (rowData) => (
                <span>{renderMLFBValue(rowData.mlfbdetailsId)}</span>
            ),
            editComponent: (props) => getMLFBDDLComponent(props),
            validate: (rowData) =>
                validateMLFB(rowData.mlfbdetailsId, rowData.leadTimeMasterId),
            customFilterAndSearch: (term, rowData) =>
                renderMLFBValue(rowData.mlfbdetailsId)
                    .toLowerCase()
                    .includes(term.toLowerCase()),
        },
        {
            title: "Po Initiate",
            field: "poinitiate",
            intialEditValue: 0,
            type: "numeric",
            editable: "onAdd",
            validate: (rowData) =>
                validateAllAsRequiredAndInteger(rowData.poinitiate),
        },
        {
            title: "PO Acknowledge",
            field: "poacknowledge",
            type: "numeric",
            intialEditValue: 0,
            validate: (rowData) =>
                validateAllAsRequiredAndInteger(rowData.poacknowledge),
        },
        {
            title: "PO Dispatch",
            field: "podispatch",
            type: "numeric",
            intialEditValue: 0,
            validate: (rowData) =>
                validateAllAsRequiredAndInteger(rowData.podispatch),
        },
        {
            title: "GRN",
            field: "grn",
            cellStyle: tableCellStyleLeadTime,
            type: "numeric",
            intialEditValue: 0,
            validate: (rowData) => validateAllAsRequiredAndInteger(rowData.grn),
        },
        {
            title: "Buffer Location1",
            field: "bufferLocation1",
            type: "numeric",
            intialEditValue: 0,
            validate: (rowData) =>
                validateAllAsRequiredAndInteger(rowData.bufferLocation1),
        },
        {
            title: "Testing",
            field: "testing",
            type: "numeric",
            intialEditValue: 0,
            validate: (rowData) => validateAllAsRequiredAndInteger(rowData.testing),
        },
        {
            title: "Buffer Location2",
            field: "bufferLocation2",
            type: "numeric",
            intialEditValue: 0,
            validate: (rowData) =>
                validateAllAsRequiredAndInteger(rowData.bufferLocation2),
        },
        {
            title: "Post Testing",
            field: "postTesting",
            type: "numeric",
            intialEditValue: 0,
            validate: (rowData) =>
                validateAllAsRequiredAndInteger(rowData.postTesting),
        },
        {
            title: "Printing",
            field: "printing",
            type: "numeric",
            intialEditValue: 0,
            validate: (rowData) => validateAllAsRequiredAndInteger(rowData.printing),
        },
        {
            title: "Visual",
            field: "visual",
            type: "numeric",
            intialEditValue: 0,
            validate: (rowData) => validateAllAsRequiredAndInteger(rowData.visual),
        },
        {
            title: "Packing",
            field: "packing",
            type: "numeric",
            intialEditValue: 0,
            validate: (rowData) => validateAllAsRequiredAndInteger(rowData.packing),
        },
        {
            title: "PD",
            field: "pd",
            type: "numeric",
            intialEditValue: 0,
            cellStyle: tableCellStyleLeadTime,
            validate: (rowData) => validateAllAsRequiredAndInteger(rowData.pd),
        },
        {
            title: "BDC",
            field: "bdc",
            type: "numeric",
            intialEditValue: 0,
            cellStyle: tableCellStyleLeadTime,
            validate: (rowData) => validateAllAsRequiredAndInteger(rowData.bdc),
        },
    ];
    const [tableData, setTableData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const userdetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    function getTableData() {
        setIsLoader(true);
        getLeadMasterData(
            siteToken,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            azureToken.token
        )
            .then((response) => {
                setTableData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }

    let [itemsMLFB, setItemsMLFB] = useState([]);
    async function getMLFBDDL() {
        const response = await getMLFBsDDL(
            siteToken,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            azureToken.token
        );
        if (response && response.length > 0) {
            setItemsMLFB(
                response.map((item) => ({ value: item.id, label: item.name }))
            );
        } else {
            setItemsMLFB([]);
        }
    }
    function getMLFBDDLComponent(objProps) {
        let customStylesDefault = {
            control: (provided, state) => ({
                ...provided,
                border: "none",
                borderBottom: `1px solid #00B3B3`,
                borderRadius: 0,
                cursor: "text",
                boxShadow: "0 0 0 0 transparent !important",
                minHeight: 0,
                ":hover": {
                    border: "none",
                    borderBottom: `1px solid #00B3B3`,
                    borderRadius: 0,
                    boxShadow: "0 0 0 0 transparent !important",
                },
            }),
            singleValue: (provided, state) => ({
                ...provided,
                textAlign: "left",
            }),
            input: (provided, state) => ({
                ...provided,
                textAlign: "left",
                padding: 0,
            }),
            menu: (provided, state) => ({
                ...provided,
                textAlign: "left",
                backgroundColor: `var(--select-active-bg-color)`,
                color: `var(--select-active-text-color)`,
            }),
            menuList: (provided, state) => ({
                ...provided,
                color: `var(--select-active-text-color)`,
            }),
            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: "none",
            }),
            clearIndicator: (provided, state) => ({
                ...provided,
                padding: "1px !important",
                cursor: "pointer",
            }),
            dropdownIndicator: (provided, state) => ({
                ...provided,
                padding: "1px !important",
                cursor: "pointer",
            }),
        };
        let customStyleError = {
            ...customStylesDefault,
            control: (provided, state) => ({
                ...provided,
                border: "none",
                borderBottom: `1px solid var(--red)`,
                borderRadius: 0,
                cursor: "text",
                boxShadow: "0 0 0 0 transparent !important",
                minHeight: 0,
                ":hover": {
                    border: "none",
                    borderBottom: `1px solid var(--red)`,
                    borderRadius: 0,
                    boxShadow: "0 0 0 0 transparent !important",
                },
            }),
        };
        const mlfbData = objProps.rowData.mlfbdetailsId
            ? itemsMLFB.find((x) => x.value === objProps.rowData.mlfbdetailsId)
            : null;
        return (
            <FormControl error={!objProps.value ? true : false} fullWidth>
                <Select
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: `var(--side-drawer-bg-color)`,
                            primary25: `var(--app-permission-list-item-color)`,
                            primary50: `var(--app-permission-list-item-color)`,
                        },
                    })}
                    options={itemsMLFB}
                    value={
                        typeof objProps.value === "string" ||
                            typeof objProps.value === "number"
                            ? mlfbData
                            : objProps.value
                    }
                    onChange={(opt) => {
                        objProps.onChange(opt.value);
                    }}
                    isClearable
                    styles={
                        !objProps.value
                            ? customStyleError
                            : (!objProps.rowData.leadTimeMasterId &&
                                isMLFBExists(
                                    typeof objProps.value === "number"
                                        ? objProps.value
                                        : objProps.value.value
                                )) ||
                                (objProps.rowData.leadTimeMasterId &&
                                    isMLFBExists(
                                        typeof objProps.value === "number"
                                            ? objProps.value
                                            : objProps.value.value,
                                        objProps.rowData.leadTimeMasterId
                                    ))
                                ? customStyleError
                                : customStylesDefault
                    }
                    placeholder="Type MLFB No."
                />
                <FormHelperText>
                    {!objProps.value
                        ? "*Required"
                        : (!objProps.rowData.leadTimeMasterId &&
                            isMLFBExists(
                                typeof objProps.value === "number"
                                    ? objProps.value
                                    : objProps.value.value
                            )) ||
                            (objProps.rowData.leadTimeMasterId &&
                                isMLFBExists(
                                    typeof objProps.value === "number"
                                        ? objProps.value
                                        : objProps.value.value,
                                    objProps.rowData.leadTimeMasterId
                                ))
                            ? "Already exists"
                            : ""}
                </FormHelperText>
            </FormControl>
        );
    }
    function renderMLFBValue(value) {
        let obj = {};
        if (itemsMLFB && itemsMLFB.length > 0) {
            obj = itemsMLFB.find((c) => c.value == value);
        }
        if (obj) {
            return obj.label;
        } else {
            return "";
        }
    }
    const enterKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", enterKeyDown);

        getMLFBDDL();
        getTableData();

        return () => {
            document.removeEventListener("keydown", enterKeyDown);
        };
    }, [userdetails]);

    function getDisableTooltip(value, type) {
        return value === true
            ? "Lead Time already associated with live production orders"
            : type === "Edit"
                ? "Edit"
                : "Delete";
    }

    const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.loggedInUserId = userdetails.userId;
        updateLeadMasterData(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            newTableData

        )
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowAdd = (newTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.loggedInUserId = userdetails.userId;
        addLeadMasterData(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.roleName,
            userdetails.roleId,
            userdetails.gid,
            newTableData

        )
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "success");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowDelete = (oldTableData, resolve, reject) => {
        setIsLoader(true);
        oldTableData.loggedInUserId = userdetails.userId;
        deleteLeadMasterData(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            oldTableData
            
        )
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    return (
        <div>
            <div className="App">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <MaterialTable
                            title=""
                            columns={columns}
                            data={tableData}
                            icons={tableIcons}
                            isLoading={isLoader}
                            options={tableOptions}
                            editable={{
                                isEditable: (rowData) => !rowData.isReadOnlyLeadTime,
                                editTooltip: (rowData) =>
                                    getDisableTooltip(rowData.isReadOnlyLeadTime, "Edit"),
                                isDeletable: (rowData) => !rowData.isReadOnlyLeadTime,
                                deleteTooltip: (rowData) =>
                                    getDisableTooltip(rowData.isReadOnlyLeadTime, "Delete"),
                                onRowUpdate: (newTableData, oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowUpdate(
                                            newTableData,
                                            oldTableData,
                                            resolve,
                                            reject
                                        );
                                    }),
                                onRowAdd: (newTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowAdd(newTableData, resolve, reject);
                                    }),
                                onRowDelete: (oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        handleRowDelete(oldTableData, resolve, reject);
                                    }),
                            }}
                        />
                    </Grid>
                </Grid>
                <>
                    <SnackbarCustom
                        open={open}
                        message={snackMessage}
                        alertType={alertType}
                        handleClose={handleClose}
                    />
                </>
            </div>
        </div>
    );
}
