import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  makeStyles,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import Snackbar from "../Components/Snackbar/Snackbar";
import "./StageScanner.scss";
import { bufferLocation2Scanner } from "../../Redux/API/api_scannerStages";
import Loading from "../Components/Loading/Loading";
import { useLocation, useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  paperSty: {
    padding: "30px",
    marginTop: "10px",
  },
  childGrid: {
    marginTop: "10px",
  },
  btnGrid: {
    justifyContent: "center",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
}));

export default function StageBufferLocation2Scanner() {
  const userdetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
  const classes = useStyles();
  const siteToken = useSelector((state) => state.saveTokenReducer);
  const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const [open, setOpen] = useState(false);
  const [qrDataInput] = useState("");
  let [arr, setArr] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const location = useLocation();
  const prodOrderNo = useState(location.state.rowData);
  let history = useHistory();

  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = (e) => {
    setOpenSnackbar(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitScannedData = () => {
    handleClickOpen();
  };

  function handleQRDataInput(e) {
    let data = e.target.value;
    let jsonData;
    if (data.includes("'")) {
      let datanew = data.substr(1, data.length - 2);
      jsonData = JSON.parse(datanew);
    } else {
      jsonData = JSON.parse(data);
    }
    if (
      prodOrderNo[0].productionOrderNumber === jsonData.productionOrderNumber
    ) {
      if (arr.length === 0) {
        setDisableSubmit(false);
        setArr((arr) => [
          ...arr,
          {
            ...jsonData,
            stageName: "Buffer Location 2",
            finalQty: 0,
            Remarks: "",
          },
        ]);
      } else if (arr.length >= prodOrderNo[0].paperCount) {
        handleSnackOpen(
          `All scans completed for Production Order Number ${prodOrderNo[0].productionOrderNumber}`,
          "success"
        );
      } else {
        if (prodOrderNo[0].poleCount === 1) {
          setDisableSubmit(false);
          setArr((arr) => [
            ...arr,
            {
              ...jsonData,
              stageName: "Buffer Location 2",
              finalQty: 0,
              Remarks: "",
            },
          ]);
        } else if (arr.find((item) => item.polarity === jsonData.polarity)) {
          handleSnackOpen("QR code already scanned", "error");
        } else {
          setDisableSubmit(false);
          setArr((arr) => [
            ...arr,
            {
              ...jsonData,
              stageName: "Buffer Location 2",
              finalQty: 0,
              Remarks: "",
            },
          ]);
        }
      }
    } else {
      handleSnackOpen("PO number mismatch", "error");
    }
  }

  function postBL2ScanData(data) {
    const dataUpdated = data.map((item) => {
      item.loggedInUserId = userdetails.userId;
      return item;
    });
    bufferLocation2Scanner(
        siteToken,
        azureToken.token,
        UID.id,
        userdetails.userEmail,
        userdetails.gid,
        userdetails.roleName,
        userdetails.roleId,
        dataUpdated
      
    )
      .then((response) => {
        if (response) {
          setIsLoader(false);
          handleSnackOpen("Scanned data successfully submitted", "Success");
          setArr([]);
          setTimeout(() => {
            history.goBack();
          }, 3000);
        } else {
          setIsLoader(false);
          handleSnackOpen("Error while submitting data", "error");
          setArr([]);
        }
      })
      .catch((error) => {
        handleSnackOpen("Error : " + error, "error");
        setIsLoader(false);
      });
  }

  function getData() {
    setIsLoader(true);
    setOpen(false);
    postBL2ScanData(arr);
  }
  const enterKeyDown = (e) => {
    if (e.which === 13 || e.which === 10) {
      e.preventDefault();
      return false;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", enterKeyDown);

    return () => {
      document.removeEventListener("keydown", enterKeyDown);
    };
  }, []);
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <form>
          <Grid container spacing={1} className={classes.childGrid}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                label="QR Code Data"
                variant="filled"
                value={qrDataInput}
                onChange={handleQRDataInput}
                id="qrCodeData"
                className="pd-2 mb-1"
                placeholder="Tap here and then scan using QR scanner"
              />
            </Grid>

            <Grid item xs={12} className="pl-2">
              <div className="qr-tables">
                {arr.map((item, index) => (
                  <table className="scanner-table width-100 mt-1" key={index}>
                    <tbody>
                      <tr className="scanner-row">
                        <td className="  scanner-td">Invoice No</td>
                        <td className="  scanner-td">
                          {item.supplierInvoiceNumber}
                        </td>
                      </tr>
                      <tr>
                        <td className="  scanner-td">Date</td>
                        <td className="  scanner-td">
                          {item.supplierInvoiceDate}
                        </td>
                      </tr>
                      <tr>
                        <td className="  scanner-td">MLFB</td>
                        <td className="  scanner-td">{item.mlfb}​​​​​​​</td>
                      </tr>
                      <tr>
                        <td className="  scanner-td">Rating</td>
                        <td className="  scanner-td">{item.rating}​​​​​​​</td>
                      </tr>
                      <tr>
                        <td className="  scanner-td">Packing</td>
                        <td className="  scanner-td">{item.packingType}</td>
                      </tr>
                      <tr>
                        <td className="  scanner-td">Prod O.</td>
                        <td className="  scanner-td">
                          {item.productionOrderNumber}
                        </td>
                      </tr>
                      <tr>
                        <td className="  scanner-td">Polarity</td>
                        <td className="  scanner-td">{item.polarity}​​​​​​​</td>
                      </tr>
                      <tr>
                        <td className="  scanner-td">Quantity</td>
                        <td className="  scanner-td">{item.packedOk}</td>
                      </tr>
                    </tbody>
                  </table>
                ))}
              </div>
            </Grid>

            <Grid container spacing={1} className={classes.btnGrid}>
              <Button
                className="pt-button--secondary"
                style={{ marginRight: "15px", position: "left" }}
                onClick={() => {
                  history.goBack();
                }}
              >
                Back
              </Button>

              <Button
                className="pt-button--secondary"
                onClick={submitScannedData}
                disabled={
                  disableSubmit ||
                  !(arr && arr.length === prodOrderNo[0].paperCount)
                }
              >
                Submit Scanned Data
              </Button>
            </Grid>
          </Grid>
          <Snackbar
            open={openSnackbar}
            message={snackMessage}
            alertType={alertType}
            handleClose={handleCloseSnackbar}
          />
          {isLoader ? <Loading /> : <React.Fragment></React.Fragment>}
        </form>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to submit this data?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className="pt-button--secondary">
              Cancel
            </Button>
            <Button onClick={() => getData()} className="pt-button--primary">
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
}
