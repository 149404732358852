import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import PrintComponents from "react-print-components";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Button } from "@material-ui/core";
import QRCode from "qrcode.react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { getQRData } from "../../../Redux/API/api_Supplier";
import Loading from "../../Components/Loading/Loading";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { baseApiURL } from "../../../Utilities/utility";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(5),
    display: "flex",
  },
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function GenerateQRCode() {
  const classes = useStyles();
  const history = useHistory();
  const siteToken = useSelector((state) => state.saveTokenReducer);
  const componentRef = useRef();
  const location = useLocation();
  let [poID] = useState(location.state.rowData.productionOrderId);
  let [poNumber] = useState(location.state.rowData.productionOrderNumber);

  let [today] = useState(new Date());
  let [qrData, setQRData] = useState([]);
  let [polarityData, setPolarityData] = useState({});
  let [isLoader, setIsLoader] = useState(false);
  const userdetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    let [isPrintQRClicked, setIsPrintQRClicked] = useState(false);

    function getQRDetails() {
      setIsLoader(true);
      getQRData(
        siteToken,
        poID,
        UID.id,
        userdetails.userEmail,
        userdetails.gid,
        userdetails.roleName,
        userdetails.roleId,
        azureToken.token
      )
        .then((response) => {
          if (response.response) {
            setQRData(response.objResult.qrData);
            setPolarityData(response.objResult.polarityData);
            setIsLoader(false);
          } else {
            setIsLoader(false);
          }
        })
        .catch((error) => {
          handleSnackOpen("Error : " + error, "error");
          setIsLoader(false);
        });
    }
  const dispatchOrder = () => {
    axios({
      method: "post",
      headers: {
        Authorization: "Bearer " + siteToken.token,
        UserEmail: userdetails.userEmail,
        RoleName: userdetails.roleName,
        Gid: userdetails.gid,
        Roleid: userdetails.roleId,
        AzureToken: azureToken.token,
      },
      url:
        baseApiURL +
        "/ProdOrder/AckPODispatch?encUserId=" +
        encodeURIComponent(UID.id) +
        "&id=" +
        poID +
        "&userId=" +
        userdetails.userId,
    })
      .then((response) => {
        handleSnackOpen(response.data.result.responseMsg);
      })
      .catch((error) => {
        handleSnackOpen("Production Order Dispatch Failed");
      });
    history.push({
      pathname: "/orderdispatch",
      state: {
        prodOrderNUmber: poNumber,
      },
    });
  };
  
  useEffect(() => {
    getQRDetails();
  }, [userdetails]);

  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };
  return (
    <>
      <SnackbarCustom
        open={open}
        message={snackMessage}
        alertType={alertType}
        handleClose={handleClose}
      />

      <div className="QR-code-section">
        <h3>QR Code Generation</h3>
        <h4>Production Order Number : {poNumber}</h4>

        {/* QR Grid */}
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <PrintComponents
              trigger={
                <div>
                  <Button
                    className="pt-button--secondary pt-button--print-QRCode QR-Code-print"
                    style={{ margin: "12px 0 20px 16px" }}
                    onClick={() => setIsPrintQRClicked(true)}
                  >
                    Print QR Code
                  </Button>
                </div>
              }
            >
              {qrData.map((item, index) => (
                <div
                  style={{
                    padding: "30px 30px 70px 0px",
                    width: "100%",
                  }}
                  id={index}
                >
                  <Grid
                    container
                    spacing={3}
                    ref={componentRef}
                    className={classes.pagePrint}
                  >
                    <table
                      style={{
                        padding: "2cm 2.5cm 0cm",
                      }}
                    >
                      <tbody>
                        <tr style={{ display: "flex" }}>
                          <td
                            style={{
                              borderRight: "1px solid #333333",
                              borderLeft: "1px solid #333333",
                              borderTop: "1px solid #333333",
                              borderBottom: "1px solid #333333",
                            }}
                          >
                            <div
                              className="d-flex"
                              style={{
                                width: "450px",
                                fontSize: "30px",
                              }}
                            >
                              <span
                                style={{
                                  padding: "7px 14px",
                                  minWidth: "200px",
                                  maxWidth: "200px",
                                  borderRight: "1px solid #333333",
                                }}
                              >
                                Invoice No.
                              </span>
                              <span
                                style={{
                                  padding: "7px 10px",
                                }}
                              >
                                {item.supplierInvoiceNumber}
                              </span>
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                width: "450px",
                                fontSize: "30px",
                                borderTop: "1px solid #333333",
                              }}
                            >
                              <span
                                style={{
                                  padding: "7px 14px",
                                  minWidth: "200px",
                                  maxWidth: "200px",
                                  borderRight: "1px solid #333333",
                                }}
                              >
                                Date
                              </span>
                              <span
                                style={{
                                  padding: "7px 14px",
                                }}
                              >
                                {item.dispatchDate
                                  .toUpperCase()
                                  .replace(",", "")}
                              </span>
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                width: "450px",
                                fontSize: "30px",
                                borderTop: "1px solid #333333",
                              }}
                            >
                              <span
                                style={{
                                  padding: "7px 14px",
                                  minWidth: "200px",
                                  maxWidth: "200px",
                                  borderRight: "1px solid #333333",
                                }}
                              >
                                MLFB
                              </span>
                              <span
                                style={{
                                  padding: "7px 14px",
                                }}
                              >
                                {item.mlfb}
                              </span>
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                width: "450px",
                                fontSize: "30px",
                                borderTop: "1px solid #333333",
                              }}
                            >
                              <span
                                style={{
                                  padding: "7px 14px",
                                  minWidth: "200px",
                                  maxWidth: "200px",
                                  borderRight: "1px solid #333333",
                                }}
                              >
                                Rating
                              </span>
                              <span
                                style={{
                                  padding: "7px 14px",
                                }}
                              >
                                {item.rating}
                              </span>
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                width: "450px",
                                fontSize: "30px",
                                borderTop: "1px solid #333333",
                                letterSpacing: "-2px",
                              }}
                            >
                              <span
                                style={{
                                  padding: "7px 14px",
                                  minWidth: "200px",
                                  maxWidth: "200px",
                                  borderRight: "1px solid #333333",
                                }}
                              >
                                Packing
                              </span>
                              <span
                                style={{
                                  padding: "7px 14px",
                                }}
                              >
                                {item.packingType}
                              </span>
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                width: "450px",
                                fontSize: "30px",
                                borderTop: "1px solid #333333",
                              }}
                            >
                              <span
                                style={{
                                  padding: "7px 14px",
                                  minWidth: "200px",
                                  maxWidth: "200px",
                                  borderRight: "1px solid #333333",
                                }}
                              >
                                PO No.
                              </span>
                              <span
                                style={{
                                  padding: "7px 14px",
                                }}
                              >
                                {item.productionOrderNumber}
                              </span>
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                width: "450px",
                                fontSize: "30px",
                                borderTop: "1px solid #333333",
                              }}
                            >
                              <span
                                style={{
                                  padding: "7px 14px",
                                  minWidth: "200px",
                                  maxWidth: "200px",
                                  borderRight: "1px solid #333333",
                                }}
                              >
                                Polarity
                              </span>
                              <span
                                style={{
                                  padding: "7px 14px",
                                }}
                              >
                                {item.polarity}
                              </span>
                            </div>
                            <div
                              className="d-flex"
                              style={{
                                width: "450px",
                                fontSize: "30px",
                                borderTop: "1px solid #333333",
                              }}
                            >
                              <span
                                style={{
                                  padding: "7px 14px",
                                  minWidth: "200px",
                                  maxWidth: "200px",
                                  borderRight: "1px solid #333333",
                                }}
                              >
                                Quantity
                              </span>
                              <span
                                style={{
                                  padding: "7px 14px",
                                }}
                              >
                                {item.packedOk}
                              </span>
                            </div>
                          </td>
                          <td
                            style={{
                              borderRight: "1px solid #333333",
                              borderLeft: "0px solid #333333",
                              borderTop: "1px solid #333333",
                              borderBottom: "1px solid #333333",
                            }}
                          >
                            <div
                              className="QR-Code"
                              style={{
                                padding: "12px 20px 0 36px",
                                marginTop: "7px",
                              }}
                            >
                              <QRCode value={JSON.stringify(item)} size={200} />
                            </div>
                            <div style={{ padding: "12px 20px 0 36px" }}>
                              <h4 style={{ margin: "8px 0 0" }}>
                                Printed on: <span>{today.toDateString()}</span>
                              </h4>
                            </div>

                            <div style={{ padding: "0 8px 16px" }}>
                              <p>
                                {" "}
                                <strong>
                                  &#64;{item.supplierName.toUpperCase()}{" "}
                                </strong>
                              </p>
                            </div>

                            <div
                              className="d-flex jc-space-bt"
                              style={{
                                borderTop: "1px solid #000000",
                                borderBottom: "1px solid #000000",
                                margin: "12px 0 0",
                                padding: "0 8px 0",
                              }}
                            >
                              <p
                                className="location"
                                style={{
                                  padding: "8px 16px",
                                  fontSize: "30px",
                                  borderRight: "1px solid",
                                  margin: "0",
                                }}
                              >
                                {item.location1}
                              </p>
                              <p
                                className="location"
                                style={{
                                  padding: "8px 16px",
                                  fontSize: "30px",
                                  borderRight: "1px solid",
                                  margin: "0",
                                }}
                              >
                                {item.location2}
                              </p>
                              <p
                                className="location"
                                style={{
                                  padding: "8px 16px",
                                  fontSize: "30px",
                                  margin: "0",
                                }}
                              >
                                {item.location3}
                              </p>
                            </div>
                            <div style={{ padding: "0 16px" }}>
                              <p style={{ fontSize: "30px", lineHeight: "0" }}>
                                {item.materialNumber}
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Grid>
                </div>
              ))}
            </PrintComponents>
            <div className="btn-div-dispatch-order-margin">
              <Button
                className="pt-button--secondary"
                disabled={!isPrintQRClicked}
                onClick={dispatchOrder}
              >
                Dispatch Order
              </Button>
            </div>
          </Grid>
        </Grid>

        {/* On Screen Data */}

        <Grid container spacing={3}>
          {qrData.map((item, index) => (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Paper className={classes.paper} id={index}>
                <Grid
                  container
                  spacing={3}
                  ref={componentRef}
                  className={classes.pagePrint}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={7}
                    className="QR-Code-details"
                  >
                    <div className="d-flex jc-space-bt">
                      <span>Invoice No.</span>
                      <span className="text-right">
                        {item.supplierInvoiceNumber}
                      </span>
                    </div>
                    <div className="d-flex jc-space-bt">
                      <span>Date</span>
                      <span className="text-right">
                        {item.dispatchDate.toUpperCase().replace(",", "")}
                      </span>
                    </div>
                    <div className="d-flex jc-space-bt">
                      <span>MLFB</span>
                      <span>{item.mlfb}</span>
                    </div>
                    <div className="d-flex jc-space-bt">
                      <span>Rating</span>
                      <span>{item.rating}</span>
                    </div>
                    <div className="d-flex jc-space-bt">
                      <span>Packing</span>
                      <span>{item.packingType}</span>
                    </div>
                    <div className="d-flex jc-space-bt">
                      <span>Prod. Order No.</span>
                      <span>{item.productionOrderNumber}</span>
                    </div>
                    <div className="d-flex jc-space-bt">
                      <span>Polarity</span>
                      <span>{item.polarity}</span>
                    </div>
                    <div className="d-flex jc-space-bt">
                      <span>Quantity</span>
                      <span>{item.packedOk}</span>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={5}
                    className="QR-Code-block d-flex jc-center"
                  >
                    <div className="QR-Code">
                      <QRCode
                        value={JSON.stringify(item)}
                        size={200}
                        includeMargin={true}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>

        {/* Footer */}
        <div className="ackFooter">
          <Button
            className="pt-button--secondary"
            onClick={() => {
              history.push({
                pathname: "/dispatchdetails",
              });
            }}
          >
            Back
          </Button>
        </div>
        {isLoader ? <Loading loaderText="Generating QR...." /> : <></>}
      </div>
    </>
  );
}
