export const authUserAction = (gid) => {
  return {
    type: "myIDLogin",
    payLoad: gid,
  };
};
export const isVerifiedOTPAction = (response) => {
  return {
    type: "isVerifiedOTP",
    payLoad: response,
  };
};

export const isForgotPasswordAction = (response) => {
  return {
    type: "isForgotPassword",
    payLoad: response,
  };
};

export const storeUserDetailsAction = (userData) => {
  return {
    type: "storeUserData",
    payLoad: userData,
  };
};
export const saveAccessTokenAction = (token) => {
  return {
    type: "saveAccessToken",
    payLoad: token,
  };
};
export const saveAzureTokenAction = (token) => {
  return {
    type: "saveAzureToken",
    payLoad: token,
  };
};
export const saveUserIdAction = (id) => {
  return {
    type: "encryptedId",
    payLoad: id,
  };
};

export const errorMessageAction = (errorMessage) => {
  return {
    type: "errorMessage",
    payLoad: errorMessage,
  };
};

export const bulkUploadSuccessAction = (uploadResponse) => {
  return {
    type: "bulkUploadSuccess",
    payLoad: uploadResponse,
  };
};

export const custHeaderAction = (custHeaderType) => {
  return {
    type: 'custHeader',
    payLoad: custHeaderType
  }
}
