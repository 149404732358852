import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { tableHeaderStyle } from "../../../Components/CommonComponents/common_components";
import { tableIcons } from "../../../../Utilities/utility";
import {
    addStageConfigMCB,
    deleteStageConfigMCB,
    getAllStageConfigsMCB,
    updateStageConfigMCB,
} from "../../../../Redux/API/api_StageConfig";
import { useSelector } from "react-redux";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";

export default function CustomEditComponent(props) {
    const [data, setData] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const userdetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };

    function validatePOInitiate(value) {
        return !value
            ? { isValid: false, helperText: "must be cheked" }
            : { isValid: true, helperText: "" };
    }
    const tableColumns = [
        {
            title: "Id",
            field: "stageConfigMcbid",
            hidden: true,
        },
        {
            title: "Stage Configuration Code",
            field: "stageConfigCode",
        },
        {
            title: "PO Initiate",
            field: "poinitiate",
            type: "boolean",
            initialEditValue: true,
            editable: "never",
            validate: (rowData) => validatePOInitiate(rowData.poinitiate),
        },
        {
            title: "PO Acknowledge",
            field: "poacknowledge",
            type: "boolean",
            initialEditValue: false,
        },
        {
            title: "PO Dispatch",
            field: "podispatch",
            type: "boolean",
            initialEditValue: false,
        },
        {
            title: "GRN",
            field: "grn",
            type: "boolean",
            initialEditValue: false,
        },
        {
            title: "Buffer Location 1",
            field: "bufferLocation1",
            type: "boolean",
            initialEditValue: false,
        },
        {
            title: "Testing",
            field: "testing",
            type: "boolean",
            initialEditValue: false,
        },
        {
            title: "Buffer Location 2",
            field: "bufferLocation2",
            type: "boolean",
            initialEditValue: false,
        },
        {
            title: "Post Testing",
            field: "postTesting",
            type: "boolean",
            initialEditValue: false,
        },
        {
            title: "Printing",
            field: "printing",
            type: "boolean",
            initialEditValue: false,
        },
        {
            title: "Visual",
            field: "visual",
            type: "boolean",
            initialEditValue: false,
        },
        {
            title: "Packing",
            field: "packing",
            type: "boolean",
            initialEditValue: false,
        },
        {
            title: "PD",
            field: "pd",
            type: "boolean",
            initialEditValue: false,
        },
        {
            title: "BDC",
            field: "bdc",
            type: "boolean",
            initialEditValue: false,
        },
    ];

    const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.loggedInUserId = userdetails.userId;
        updateStageConfigMCB(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            newTableData
           
        )
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowAdd = (newTableData, resolve, reject) => {
        setIsLoader(true);
        newTableData.loggedInUserId = userdetails.userId;
        addStageConfigMCB(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            newTableData

        )
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };
    const handleRowDelete = (oldTableData, resolve, reject) => {
        setIsLoader(true);
        oldTableData.loggedInUserId = userdetails.userId;
        deleteStageConfigMCB(
            siteToken,
            azureToken.token,
            UID.id,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            userdetails.roleId,
            oldTableData

        )
            .then((response) => {
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    resolve();
                    setIsLoader(false);
                    getTableData();
                } else {
                    handleSnackOpen(response.responseMsg, "error");
                    reject();
                    setIsLoader(false);
                }
            })
            .catch((error) => {
                handleSnackOpen("Exception : " + error, "error");
                reject();
                setIsLoader(false);
            });
    };

    function getTableData() {
        setIsLoader(true);
        getAllStageConfigsMCB(
            siteToken,
            userdetails.userEmail,
            userdetails.gid,
            userdetails.roleName,
            UID.id,
            userdetails.roleId,
            azureToken.token
        )
            .then((response) => {
                setData(response.objResult ? response.objResult : []);
                setIsLoader(false);
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen("Exception while fetching data", "error");
            });
    }
    useEffect(() => {
        getTableData();
    }, [userdetails]);

    function getDisableTooltip(value, type) {
        return userdetails.roleId !== 1
            ? "You are not authorized to take this action"
            : value === true
                ? "Stage Configuration already associated with live production orders"
                : type === "Edit"
                    ? "Edit"
                    : "Delete";
    }
    return (
        <>
            <MaterialTable
                columns={tableColumns}
                data={data}
                title=""
                icons={tableIcons}
                isLoading={isLoader}
                options={{
                    search: false,
                    draggable: false,
                    headerStyle: tableHeaderStyle,
                    maxBodyHeight: "600px",
                    toolbar: userdetails.roleId === 1,
                    addRowPosition: "first",
                    fixedColumns: {
                        left: 0,
                        right: 0,
                    },
                }}
                editable={{
                    isEditable: (rowData) =>
                        userdetails.roleId === 1 && !rowData.isreadonlyMCB,
                    editTooltip: (rowData) =>
                        getDisableTooltip(rowData.isreadonlyMCB, "Edit"),
                    isDeletable: (rowData) =>
                        userdetails.roleId === 1 && !rowData.isreadonlyMCB,
                    deleteTooltip: (rowData) =>
                        getDisableTooltip(rowData.isreadonlyMCB, "Delete"),
                    onRowAdd: (newData) =>
                        new Promise((resolve, reject) => {
                            handleRowAdd(newData, resolve, reject);
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            handleRowUpdate(newData, oldData, resolve, reject);
                        }),
                    onRowDelete: (oldData) =>
                        new Promise((resolve, reject) => {
                            handleRowDelete(oldData, resolve, reject);
                        }),
                }}
            />
            <>
                <SnackbarCustom
                    open={open}
                    message={snackMessage}
                    alertType={alertType}
                    handleClose={handleClose}
                />
            </>
        </>
    );
}
