import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { Button } from "@material-ui/core";
import { getStageDetails } from "../../../Redux/API/api_Stages";
import { PDReject } from "../../../Redux/API/api_Stages";
import { useSelector } from "react-redux";
import { baseApiURL } from "../../../Utilities/utility";
import { tableOptions } from "../../Components/CommonComponents/common_components";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { getMLFBsDDL } from "../../../Redux/API/api_Common";

export default function StorePD() {
  const userdetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
  const [pdData, setPdData] = useState([]);
  let [mlfbData, setMLFBData] = useState([]);
  const [tableLoader, setTableLoader] = useState(true);
  const apiURL = baseApiURL + "/ProdOrder/GetPDPos";
  const siteToken = useSelector((state) => state.saveTokenReducer);
const azureToken = useSelector((state) => state.saveAzureTokenReducer);
const [open, setOpen] = useState(false);
const [snackMessage, setSnackMessage] = useState("");
const [alertType, setAlertType] = useState("");
const handleSnackOpen = (text, type) => {
  setSnackMessage(text);
  setAlertType(type);
  setOpen(true);
};
const handleClose = (e) => {
  setOpen(false);
};

async function getMLFBs() {
  const data = await getMLFBsDDL(
    siteToken,
    UID.id,
    userdetails.userEmail,
    userdetails.gid,
    userdetails.roleName,
    userdetails.roleId,
    azureToken.token
  );
  setMLFBData(data);
}
function renderMLFBValue(value) {
  let obj = {};
  if (mlfbData && mlfbData.length > 0) {
    obj = mlfbData.find((c) => c.id == value);
  }
  if (obj) {
    return obj.name;
  } else {
    return "";
  }
}

function apiReject(data) {
  setTableLoader(true);
    const rejectURL = baseApiURL + "/ProdOrder/PDReject?userId=" + UID.id;
  const apiData = {
    productionOrderNumber: data.productionOrderNumber,
    productionOrderDetailsId: data.productionOrderId,
    loggedInUserId: userdetails.userId,
  };
  PDReject(
    rejectURL,
    apiData,
    siteToken,
    userdetails.userEmail,
    userdetails.roleName,
    userdetails.gid,
    userdetails.roleId,
    azureToken.token
  )
    .then((response) => {
      setTableLoader(false);
      handleSnackOpen(
        response.result.responseMsg,
        response.result.response ? "success" : "error"
      );
      getTableData();
    })
    .catch((error) => {
      setTableLoader(false);
      console.log("Error: " + error, "error");
    });
}

const columns = [
  {
    title: "Production Order",
    field: "productionOrderNumber",
  },
  {
    title: "MLFB",
    field: "mlfb",
    render: (rowData) => <>{renderMLFBValue(rowData.mlfb)}</>,
    customFilterAndSearch: (term, rowData) =>
      renderMLFBValue(rowData.mlfb).toLowerCase().includes(term.toLowerCase()),
  },
  {
    title: "Rating",
    field: "rating",
  },
  {
    title: "Batch Qty",
    field: "batchQty",
  },
  { title: "Final Packing Qty", field: "finalQtyPacking" },
  {
    title: "Reject",
    field: "reject",
    render: (rowData) => (
      <Button className="pt-button--delete" onClick={() => apiReject(rowData)}>
        Reject
      </Button>
    ),
  },
];

function getTableData() {
  setTableLoader(true);
  getStageDetails(
    apiURL,
    siteToken,
    UID.id,
    userdetails.userEmail,
    userdetails.gid,
    userdetails.roleName,
    userdetails.roleId,
    azureToken.token
  )
    .then((response) => {
      setPdData(response.objResult ? response.objResult : []);
      setTableLoader(false);
    })
    .catch((error) => {
      setTableLoader(false);
      handleSnackOpen("Error : " + error, "error");
    });
}

  useEffect(() => {
    getTableData();
    getMLFBs();
  }, [userdetails]);

  return (
    <>
      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
      <h2>PD</h2>
      <div>
        <MaterialTable
          title="PD"
          columns={columns}
          data={pdData}
          isLoading={tableLoader}
          options={tableOptions}
        />
      </div>
    </>
  );
}
