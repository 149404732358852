import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { BrowserRouter } from "react-router-dom";
import { LoginRoute, ResetPasswordRoute } from "./Routing/LoginRoute";
import {
  MasterRoute,
  OtherRoute,
  SupplierRoute,
  GroupRoute,
} from "./Routing/MasterRoute";
import Header from "./Pages/Components/Header/Header";
import Loader from "./Pages/Components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  authUserAction,
  saveAccessTokenAction,
  saveAzureTokenAction,
  storeUserDetailsAction,
} from "../src/Redux/Actions/actions";
import IdleTimer from "react-idle-timer";
import "./assets/breakpoint.scss";
import "./App.css";
import { useMsal } from "@azure/msal-react";

import { authenticateUserBasedOnValidToken } from "./Redux/API/api_Login";
import UnauthorizedAccess from "./Pages/UnauthorizedAccess/UnauthorizedAccess";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

export default function App() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [isAuthorized, setIsAuthorized] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const keyVal = useSelector((state) => state.authUserReducer.gid);
  const isOTPVerified = useSelector((state) => state.isVerifiedOTPReducer);
  const isForgotPassword = useSelector(
    (state) => state.isForgotPasswordReducer
  );

  const { instance, accounts } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setDialogOpen(false);
  };
  const handleDialogOpen = (text) => {
    setDialogContent(text);
    setDialogOpen(true);
  };
  function sessionExpire(auth, dispatch) {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    window.history.pushState({ path: newurl }, "", `/`);
    localStorage.clear();
    sessionStorage.clear();
    if (auth !== undefined) {
      dispatch(storeUserDetailsAction(""));

      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    window.location.href = "/";
  }

  function authenticateUserWithGID(gid) {
    authenticateUserBasedOnValidToken(dispatch, activeAccount.idToken, gid)
      .then((response) => {
        if (response) {
          setDialogOpen(false);
          setIsLoader(false);
        } else {
          setIsLoader(false);
          setDialogOpen(true);
        }
      })
      .catch((error) => {
        setIsLoader(false);
        setDialogOpen(true);
      });
  }

  function getPasswordValidity() {
    let dateDiffDays = !userDetails.passwordChangedOn
      ? 0
      : Math.round(
          (new Date() - new Date(userDetails.passwordChangedOn)) /
            (1000 * 60 * 60 * 24)
        );
    let validityTill = 90 - dateDiffDays;
    return validityTill;
  }

  useEffect(() => {
    if (activeAccount) {
      setIsLoader(true);
      if (activeAccount) {
        let gid = accounts[0].idTokenClaims.gid;
        dispatch(authUserAction(gid));
        dispatch(saveAzureTokenAction(activeAccount.idToken));
        authenticateUserWithGID(gid);
      }
    } else {
      setIsLoader(false);
    }
  }, [accounts]);

  return (
    <BrowserRouter>
      {isOTPVerified && isForgotPassword ? (
        <div className="potts-master-block">
          <Header>Header Component</Header>
          <main className="main-component">{ResetPasswordRoute}</main>
        </div>
      ) : auth.isLoading || isLoader ? (
        <Loader />
      ) : (userDetails && userDetails.roleId > 0) || auth.user ? (
        <div className="potts-master-block">
          <Header>Header Component</Header>
          <main className="main-component">
            {userDetails.roleId === 2 &&
            (!userDetails.changePassword || getPasswordValidity() <= 0)
              ? ResetPasswordRoute
              : userDetails.roleId === 1
              ? MasterRoute
              : userDetails.roleId === 2
              ? SupplierRoute
              : userDetails.roleId >= 9 && userDetails.roleId <= 18
              ? GroupRoute
              : OtherRoute}
          </main>
        </div>
      ) : (
        LoginRoute
      )}
      <div>
        <IdleTimer
          timeout={1000 * 60 * 15}
          onIdle={sessionExpire}
          debounce={250}
        />
      </div>
      <>
        <Dialog
          disableEscapeKeyDown
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Error</DialogTitle>
          <DialogContent>
            {dialogContent ? (
              <DialogContentText>{dialogContent}</DialogContentText>
            ) : (
              <UnauthorizedAccess />
            )}
          </DialogContent>
        </Dialog>
      </>
    </BrowserRouter>
  );
}
