import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Grid } from "@material-ui/core";
import Cognisphere from "../../assets/images/Cognisphere.png";
import Loader from "../Components/Loading/Loading";
import "./Home.scss";
import { getDynamicHomeMenus } from "../../Redux/API/api_UserManagement";

export default function Home() {
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const [routes, setRoutes] = useState([]);

    function getDynamicMenus() {
        getDynamicHomeMenus(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId
        )
            .then((response) => {
                if (response) {
                    setRoutes(response);
                } else {
                    setRoutes([]);
                }
            })
            .catch((error) => {
                console.log("Error: " + error, "error");
            });
    }

    var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
    window.history.pushState({ path: newurl }, "", newurl);

    useEffect(() => {
        if (userDetails && userDetails.userId > 0 && siteToken.token !== "") {
            // getMenus(userDetails);
            getDynamicMenus();
        }
    }, [userDetails, siteToken]);

    return (
        <>
            <img
                src={Cognisphere}
                alt="Cognisphere"
                className="homepage-cognisphere"
            />
            {routes.length ? (
                <div className="bg-landing-page">
                    <Container maxWidth="lg">
                        <Grid container className="homepage-container">
                            <Grid item xs={12} sm={12} md={12} lg={5} className="z-9">
                                <div className="logged-in-user">
                                    <h1 className="logged-in-user-heading">
                                        <span className="logged-in-greetings">Hello,</span>
                                        <span className="logged-in-user-name">
                                            {userDetails.userFirstName}
                                        </span>
                                    </h1>
                                    <p className="logged-in-project-desc">
                                        Welcome to Production Order Track & Trace System (POTTS)
                                    </p>
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={7}
                                className="landing-page-menu">
                                <Grid container spacing={2}>
                                    {routes.map((item, index) => (
                                        <Grid item xs={12} sm={6} md={6} key={index}>
                                            <Link to={item.menuLink} key={index}>
                                                <div className="menu-item-container">
                                                    <div className="menu-item-text-block">
                                                        <span>{item.menuName}</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
}
