export function getStageDetails(
  url,
  siteToken,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(url + "?userId=" + encodeURIComponent(userId), options)
    .then((response) => response.json())
    .then((response) => {
      return response.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function PDReject(
  url,
  data,
    siteToken,
  userEmail,
  roleName,
  gid,
  roleId,
  azureToken
) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
    body: JSON.stringify(data),
  };
  return fetch(url, options)
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
