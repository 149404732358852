import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { getStageDetails } from "../../../Redux/API/api_Stages";
import { useSelector } from "react-redux";
import { baseApiURL } from "../../../Utilities/utility";
import { tableOptions } from "../../Components/CommonComponents/common_components";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";

export default function BDC() {
  const [bdcData, setBdcData] = useState([]);
  const [tableLoader, setTableLoader] = useState(true);
  const apiURL = baseApiURL + "/ProdOrder/GetBDCPos";
  const siteToken = useSelector((state) => state.saveTokenReducer);
  const userdetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    let history = useHistory();

    const columns = [
      {
        title: "Scan PO",
        field: "scanPO",
        render: (rowData) => scanProdOrder(rowData),
      },
      {
        title: "Production Order",
        field: "productionOrderNumber",
      },
      { title: "Pole Count", field: "poleCount" },
      { title: "QR Paper Count", field: "paperCount" },
    ];

    function scanProdOrder(data) {
      return (
        <Button
          className="pt-button--tertiary"
          onClick={() => {
            history.push({
              pathname: "/stageBDCScanner",
              state: {
                rowData: data,
              },
            });
          }}>
          Scan PO
        </Button>
      );
    }
    function getTableData() {
      setTableLoader(true);
      getStageDetails(
        apiURL,
        siteToken,
        UID.id,
        userdetails.userEmail,
        userdetails.gid,
        userdetails.roleName,
        userdetails.roleId,
        azureToken.token
      )
        .then((response) => {
          setBdcData(response.objResult ? response.objResult : []);
          setTableLoader(false);
        })
        .catch((error) => {
          setTableLoader(false);
          console.log("Error: " + error, "error");
        });
    }

  useEffect(() => {
    getTableData();
  }, [userdetails]);

  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };
  const handleClose = (e) => {
    setOpen(false);
  };

  return (
    <div>
      <h3>Stage BDC</h3>
      <MaterialTable
        title="Stage BDC"
        columns={columns}
        data={bdcData}
        isLoading={tableLoader}
        options={tableOptions}
      />
      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}
