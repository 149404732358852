import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Switch,
  Typography,
  Grid,
} from "@material-ui/core";
import SampleTemplate from "../../../src/assets/template/SampleTemplate.xlsx";
import {
  bulkUploadPOList,
  bulkUploadSmallPO,
} from "../../Redux/API/api_bulkUploadPOList";
import Loading from "../Components/Loading/Loading";

const useStyles = makeStyles(() => ({
  downloadButonText: {
    color: "#008aa6",
  },
  downloadText: {
    marginRight: "10px",
  },
}));

export default function BulkUpload() {
  const classes = useStyles();
    const UID = useSelector((state) => state.saveUserIdReducer);
  const [isSmallPO, setIsSmallPO] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [inputFileValue, setInputFileValue] = useState(Date.now);
  const [btnUploadClicked] = useState("");

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState("");
  let [isLoader, setIsLoader] = useState(false);

  const siteToken = useSelector((state) => state.saveTokenReducer);
  const azureToken = useSelector((state) => state.saveAzureTokenReducer);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const uploadResponse = useSelector((state) => state.bulkUploadSuccessReducer);

  const onSubmit = (e) => {
    setIsLoader(true);
    if (!selectedFile && selectedFile.length <= 0) {
      setIsLoader(false);
      setDialogContent("Select a file.");
      setDialogOpen(true);
    } else {
      const formData = new FormData();

      formData.append("file", selectedFile);
      formData.append("UserId", userDetails.userId);
      uploadFile(formData);
    }
  };

  function uploadFile(formData) {
    if (isSmallPO) {
      bulkUploadSmallPO(
          siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.roleName,
          userDetails.roleId,
          formData
      )
        .then((response) => {
          if (response.response) {
            setIsLoader(false);
            handleDialogMessage(response.responseMsg);
          } else {
            setIsLoader(false);
            setSelectedFile(null);
            setInputFileValue(Date.now);
            handleDialogMessage(
              response.responseMsgs && response.responseMsgs.length > 0
                ? response.responseMsgs
                : response.responseMsg
            );
          }
        })
        .catch((error) => {
          setIsLoader(false);
          setSelectedFile(null);
          setInputFileValue(Date.now);
          handleDialogMessage("Exception in upload");
        });
    } else {
      bulkUploadPOList(
          siteToken,
          azureToken.token,
          UID.id,
          userDetails.userEmail,
          userDetails.gid,
          userDetails.roleName,
          userDetails.roleId,
          formData
      )
        .then((response) => {
          if (response.response) {
            setIsLoader(false);
            handleDialogMessage(response.responseMsg);
          } else {
            setIsLoader(false);
            setSelectedFile(null);
            setInputFileValue(Date.now);
            handleDialogMessage(
              response.responseMsgs && response.responseMsgs.length > 0
                ? response.responseMsgs
                : response.responseMsg
            );
          }
        })
        .catch((error) => {
          setIsLoader(false);
          setSelectedFile(null);
          setInputFileValue(Date.now);
          handleDialogMessage("Exception in upload");
        });
    }
  }

  const updateExcelHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDialogMessage = (text) => {
    setDialogContent(text);
    setDialogOpen(true);
  };
  const handleClose = () => {
    if (
      typeof dialogContent === "string" &&
      dialogContent.toLowerCase().includes("success")
    ) {
      window.location.reload();
    }
    setDialogOpen(false);
  };
  const toggleChange = (e) => {
    setIsSmallPO(e.target.checked);
  };
  useEffect(() => {
    if (
      uploadResponse?.responseMsg !== "" &&
      btnUploadClicked === "UploadClicked"
    ) {
      if (uploadResponse.response) {
        handleDialogMessage(uploadResponse.responseMsg);
        setInputFileValue(Date.now);
        setSelectedFile(null);
      } else {
        handleDialogMessage(uploadResponse.responseMsg);
        setInputFileValue(Date.now);
        setSelectedFile(null);
      }
    }
  }, [uploadResponse, btnUploadClicked]);

  return (
    <>
      <div className="bulk-upload-block">
        <h2 className="bulk-upload-title">Bulk Upload</h2>
        <div className="bulk-upload-sample-template">
          <div>
            <Typography component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Regular PO</Grid>
                <Grid item>
                  <Switch checked={isSmallPO} onChange={toggleChange} />
                </Grid>
                <Grid item>Small PO</Grid>
              </Grid>
            </Typography>
          </div>
          <div>
            <form
              className="bulk-upload-form"
            >
              <TextField
                label="Bulk Upload File"
                variant="filled"
                className="bulk-upload"
                type="file"
                name="bulkUpload"
                onChange={updateExcelHandler}
                key={inputFileValue}
              />
              <Button
                className="pt-button--secondary bulk-upload-submit-btn"
                type="button"
                disabled={selectedFile ? false : true}
                onClick={onSubmit}
              >
                Submit
              </Button>
            </form>
            <span>Upload file format in excel i.e .xsls, .xls format only</span>
          </div>
          <div>
            <span className={classes.downloadText}>
              Download Sample Template
            </span>
            <Button className="pt-button--secondary bulk-upload-submit-btn">
              <a
                className={classes.downloadButonText}
                href={SampleTemplate}
                download="BulkUpload_Template.xlsx"
              >
                Download
              </a>
            </Button>
          </div>
        </div>
      </div>
      <div>
        <Dialog
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Bulk Upload</DialogTitle>
          <DialogContent>
            {dialogContent && typeof dialogContent === "object" ? (
              <DialogContentText id="alert-dialog-description">
                <ol className="ol-bulkUpload-Error">
                  {dialogContent.map((c) => (
                    <li>
                      {c.map((x) =>
                        x.includes("Production Order Number :") ? (
                          x
                        ) : (
                          <ul className="ul-bulkUpload-Error">
                            <li>{x}</li>
                          </ul>
                        )
                      )}
                    </li>
                  ))}
                </ol>
              </DialogContentText>
            ) : (
              <DialogContentText id="alert-dialog-description">
                {dialogContent}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} className="pt-button--secondary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
        {isLoader ? <Loading loaderText="Upload in progress...." /> : <></>}
      </div>
    </>
  );
}
