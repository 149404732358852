import { baseApiURL } from "../../Utilities/utility";

export function bulkUploadPOList(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    formData 
) {
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
    body: formData,
  };
    return fetch(baseApiURL + "/ProdOrder/InitiatePo?encUserId=" + encodeURIComponent(userId), options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function bulkUploadSmallPO(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    formData
) {
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
    body: formData,
  };
    return fetch(baseApiURL + "/ProdOrder/SmallLotPOInitiateToPacking?encUserId=" + encodeURIComponent(userId), options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getPoListData(
  siteToken,
  userId,
  userEmail,
  gid,
  roleName,
  roleId,
  azureToken
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(
    baseApiURL + "/ProdOrder/GetPos?userId=" + encodeURIComponent(userId),
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function deleteProductionOrder(
    siteToken,
    azureToken,
    encUserId,
 
  userId,
  userEmail,
  gid,
  roleName,
    roleId,
    value,
  
) {
  const options = {
    method: "delete",
    headers: {
      Authorization: "Bearer " + siteToken.token,
      UserEmail: userEmail,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleId,
      AzureToken: azureToken,
    },
  };
  return fetch(
    
      baseApiURL + "/ProdOrder/DeletePo?encUserId=" + encodeURIComponent(encUserId) + "&Id=" + value + "&userId=" + userId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
