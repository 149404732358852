import { baseApiURL } from "../../Utilities/utility";
export function getSupplierData(
    siteToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    azureToken
) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
    };
    return fetch(
        baseApiURL +
        "/Admin/GetSupplierDetails?userId=" +
        encodeURIComponent(userId),
        options
    )
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function addSupplierData(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data
) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PostSupplierDetails?userId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function updateSupplierData(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data


) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/PutSupplierDetails?userId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteSupplierData(
    siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,
    data
    
) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
        },
        body: JSON.stringify(data),
    };
    return fetch(baseApiURL + "/Admin/DeleteSupplierDetails?userId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}