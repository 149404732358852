import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../Pages/Home/Home";
import BulkUploadAndPOList from "../Pages/BulkUploadAndPOList/BulkUploadAndPOList";
import ProductionOrderWorkflow from "../Pages/ProductionOrderWorkflow/POWorkflow";
import GRNStatus from "../Pages/GRNStatus/GRNWorkflow";
import AcknowledgeOrder from "../Pages/AcknowledgeOrder/AckOrder";
import Admin from "../Pages/Administrator/Admin";
import GenerateQRCode from "../Pages/AcknowledgeOrder/ComponentsAckOrder/GenerateQRCode";
import ReprintQR from "../Pages/ReprintQR/ReprintQR";
import UserList from "../Pages/Administrator/UserManagementComponent/UserList";
import GRNDetails from "../Pages/Stages/GRN/GRNDetails";
import StoreGRN from "../Pages/Stages/GRN/StoreGRN";
import BufferLocation1 from "../Pages/Stages/BufferLocation1/BufferLocation1";
import Testing from "../Pages/Stages/Testing/Testing";
import BufferLocation2 from "../Pages/Stages/BufferLocation2/BufferLocation2";
import PostTesting from "../Pages/Stages/PostTesting/PostTesting";
import Packing from "../Pages/Stages/Packing/Packing";
import Printing from "../Pages/Stages/Printing/Printing";
import Visual from "../Pages/Stages/Visual/Visual";
import StagePD from "../Pages/Stages/PD/PD";
import StorePD from "../Pages/Stages/PD/StorePD";
import StageBDC from "../Pages/Stages/BDC/BDC";
import StoreBDC from "../Pages/Stages/BDC/StoreBDC";
import DispatchDetails from "../Pages/AcknowledgeOrder/ComponentsAckOrder/DispatchDetails";
import ProductionOrderDispatched from "../Pages/AcknowledgeOrder/ComponentsAckOrder/ProductionOrderDispatched";
import AcknowledegProductionOrder from "../Pages/AcknowledgeOrder/ComponentsAckOrder/AcknowledegProductionOrder";
import StageConfiguration from "../Pages/Administrator/StageConfiguration/StageConfiguration";
import AdminMasterTabs from "../Pages/Administrator/AdminMasterData/AdminMasterTabs";
import Reports from "../Pages/Administrator/Reports/Reports";
import EmailTemplate from "../Pages/Administrator/EmailTemplate/EmailTemplate";
import StageGRNScanner from "../Pages/StageScanner/StageGRNScanner";
import StagePackingScanner from "../Pages/StageScanner/StagePackingScanner";
import StageBufferLocation1Scanner from "../Pages/StageScanner/StageBufferLocation1Scanner";
import StagePDScanner from "../Pages/StageScanner/StagePDScanner";
import StageBDCScanner from "../Pages/StageScanner/StageBDCScanner";
import StagePrintingScanner from "../Pages/StageScanner/StagePrintingScanner";
import StageVisualScanner from "../Pages/StageScanner/StageVisualScanner";
import StageTestingScanner from "../Pages/StageScanner/StageTestingScanner";
import StagePostTestingScanner from "../Pages/StageScanner/StagePostTestingScanner";
import StageBufferLocation2Scanner from "../Pages/StageScanner/StageBufferLocation2Scanner";
import UnauthorizedAccess from "../Pages/UnauthorizedAccess/UnauthorizedAccess";
import Error from "../Pages/Error/Error404";
import ChangePassword from "../Pages/ResetPassword/ChangePassword";
import POListTable from "../Pages/BulkUploadAndPOList/POList";

export const MasterRoute = (
    <Switch>
        <Redirect from="/?Code*" to="/" />
        <Redirect from="/signin-oidc*" to="/" />
        <Route exact path="/" render={() => <Home />} />
        <Route
            exact
            path="/poworkflow"
            render={() => <ProductionOrderWorkflow />}
        />
        <Route
            exact
            path="/grnworkflow"
            render={() => <GRNStatus />}
        />
        <Route exact path="/bulkUploadPO" render={() => <BulkUploadAndPOList />} />
        <Route exact path="/poList" render={() => <POListTable />} />
        <Route exact path="/ackorder" render={() => <AcknowledgeOrder />} />
        <Route exact path="/admin" render={() => <Admin />} />
        <Route exact path="/reprintqr" render={() => <ReprintQR />} />
        <Route exact path="/grn" render={() => <GRNDetails />} />
        <Route exact path="/storeGRN" render={() => <StoreGRN />} />
        <Route exact path="/bufferlocation1" render={() => <BufferLocation1 />} />
        <Route exact path="/testing" render={() => <Testing />} />
        <Route exact path="/bufferlocation2" render={() => <BufferLocation2 />} />
        <Route exact path="/posttesting" render={() => <PostTesting />} />
        <Route exact path="/packing" render={() => <Packing />} />
        <Route exact path="/printing" render={() => <Printing />} />
        <Route exact path="/visual" render={() => <Visual />} />
        <Route exact path="/stagePD" render={() => <StagePD />} />
        <Route exact path="/storePD" render={() => <StorePD />} />
        <Route exact path="/stageBDC" render={() => <StageBDC />} />
        <Route exact path="/storeBDC" render={() => <StoreBDC />} />
        <Route exact path="/dispatchdetails" render={() => <DispatchDetails />} />
        <Route exact path="/generateQR" render={() => <GenerateQRCode />} />
        <Route
            exact
            path="/orderdispatch"
            render={() => <ProductionOrderDispatched />}
        />
        <Route
            exact
            path="/bulkuploadandpo"
            render={() => <BulkUploadAndPOList />}
        />
        <Route
            exact
            path="/ackprodorder"
            render={() => <AcknowledegProductionOrder />}
        />
        <Route exact path="/ackorder" render={() => <AcknowledgeOrder />} />

        <Route
            exact
            path="/stageConfiguration"
            render={() => <StageConfiguration />}
        />
        <Route exact path="/userList" render={() => <UserList />} />

        <Route exact path="/masterDetails" render={() => <AdminMasterTabs />} />
        <Route exact path="/reports" render={() => <Reports />} />
        <Route exact path="/emailTemplate" render={() => <EmailTemplate />} />
        <Route
            exact
            path="/UnauthorizedAccess"
            render={() => <UnauthorizedAccess />}
        />

        <Route exact path="/stageGRNScanner" render={() => <StageGRNScanner />} />
        <Route
            exact
            path="/stageBufferLocation1Scanner"
            render={() => <StageBufferLocation1Scanner />}
        />
        <Route
            exact
            path="/stageTestingScanner"
            render={() => <StageTestingScanner />}
        />
        <Route
            exact
            path="/stageBufferLocation2Scanner"
            render={() => <StageBufferLocation2Scanner />}
        />
        <Route
            exact
            path="/stagePostTestingScanner"
            render={() => <StagePostTestingScanner />}
        />
        <Route
            exact
            path="/stagePrintingScanner"
            render={() => <StagePrintingScanner />}
        />
        <Route
            exact
            path="/stageVisualScanner"
            render={() => <StageVisualScanner />}
        />
        <Route
            exact
            path="/stagePackingScanner"
            render={() => <StagePackingScanner />}
        />
        <Route exact path="/stagePDScanner" render={() => <StagePDScanner />} />
        <Route exact path="/stageBDCScanner" render={() => <StageBDCScanner />} />
        <Route exact path="/changePassword" render={() => <ChangePassword />} />
        <Route render={() => <Error />} />
    </Switch>
);

export const OtherRoute = (
  <Switch>
    <Redirect from="/?Code*" to="/" />
    <Redirect from="/signin-oidc*" to="/" />
    <Route exact path="/" render={() => <Home />} />
    <Route
      exact
      path="/poworkflow"
      render={() => <ProductionOrderWorkflow />}
    />
    <Route exact path="/grnworkflow" render={() => <GRNStatus />} />
    <Route exact path="/bulkUploadPO" render={() => <BulkUploadAndPOList />} />
    <Route exact path="/poList" render={() => <POListTable />} />
    <Route exact path="/ackorder" render={() => <AcknowledgeOrder />} />
    <Route exact path="/reprintqr" render={() => <ReprintQR />} />
    <Route exact path="/grn" render={() => <GRNDetails />} />
    <Route exact path="/storeGRN" render={() => <StoreGRN />} />
    <Route exact path="/bufferlocation1" render={() => <BufferLocation1 />} />
    <Route exact path="/testing" render={() => <Testing />} />
    <Route exact path="/bufferlocation2" render={() => <BufferLocation2 />} />
    <Route exact path="/posttesting" render={() => <PostTesting />} />
    <Route exact path="/packing" render={() => <Packing />} />
    <Route exact path="/printing" render={() => <Printing />} />
    <Route exact path="/visual" render={() => <Visual />} />
    <Route exact path="/stagePD" render={() => <StagePD />} />
    <Route exact path="/storePD" render={() => <StorePD />} />
    <Route exact path="/stageBDC" render={() => <StageBDC />} />
    <Route exact path="/storeBDC" render={() => <StoreBDC />} />
    <Route exact path="/dispatchdetails" render={() => <DispatchDetails />} />
    <Route exact path="/generateQR" render={() => <GenerateQRCode />} />
    <Route
      exact
      path="/orderdispatch"
      render={() => <ProductionOrderDispatched />}
    />
    <Route
      exact
      path="/bulkuploadandpo"
      render={() => <BulkUploadAndPOList />}
    />
    <Route
      exact
      path="/ackprodorder"
      render={() => <AcknowledegProductionOrder />}
    />
    <Route exact path="/ackorder" render={() => <AcknowledgeOrder />} />

    <Route
      exact
      path="/stageConfiguration"
      render={() => <StageConfiguration />}
    />
    <Route exact path="/emailTemplate" render={() => <EmailTemplate />} />
    <Route
      exact
      path="/UnauthorizedAccess"
      render={() => <UnauthorizedAccess />}
    />

    <Route exact path="/stageGRNScanner" render={() => <StageGRNScanner />} />
    <Route
      exact
      path="/stageBufferLocation1Scanner"
      render={() => <StageBufferLocation1Scanner />}
    />
    <Route
      exact
      path="/stageTestingScanner"
      render={() => <StageTestingScanner />}
    />
    <Route
      exact
      path="/stageBufferLocation2Scanner"
      render={() => <StageBufferLocation2Scanner />}
    />
    <Route
      exact
      path="/stagePostTestingScanner"
      render={() => <StagePostTestingScanner />}
    />
    <Route
      exact
      path="/stagePrintingScanner"
      render={() => <StagePrintingScanner />}
    />
    <Route
      exact
      path="/stageVisualScanner"
      render={() => <StageVisualScanner />}
    />
    <Route
      exact
      path="/stagePackingScanner"
      render={() => <StagePackingScanner />}
    />
    <Route exact path="/stagePDScanner" render={() => <StagePDScanner />} />
    <Route exact path="/stageBDCScanner" render={() => <StageBDCScanner />} />
    <Route exact path="/changePassword" render={() => <ChangePassword />} />
    <Route exact path="/reports" render={() => <Reports />} />
    <Route render={() => <Error />} />
  </Switch>
);

export const SupplierRoute = (
  <Switch>
    <Redirect from="/?Code*" to="/" />
    <Redirect from="/signin-oidc*" to="/" />
    <Route exact path="/" render={() => <Home />} />
    <Route
      exact
      path="/poworkflow"
      render={() => <ProductionOrderWorkflow />}
    />
    <Route exact path="/ackorder" render={() => <AcknowledgeOrder />} />
    <Route exact path="/dispatchdetails" render={() => <DispatchDetails />} />
    <Route exact path="/reprintqr" render={() => <ReprintQR />} />
    <Route exact path="/generateQR" render={() => <GenerateQRCode />} />
    <Route
      exact
      path="/orderdispatch"
      render={() => <ProductionOrderDispatched />}
    />
    <Route render={() => <Error />} />
  </Switch>
);

export const GroupRoute = (
  <Switch>
    <Redirect from="/?Code*" to="/" />
    <Redirect from="/signin-oidc*" to="/" />
    <Route exact path="/" render={() => <Home />} />
    <Route exact path="/grnworkflow" render={() => <GRNStatus />} />
    <Route exact path="/grn" render={() => <GRNDetails />} />
    <Route exact path="/stageGRNScanner" render={() => <StageGRNScanner />} />
    <Route exact path="/bufferlocation1" render={() => <BufferLocation1 />} />
    <Route
      exact
      path="/stageBufferLocation1Scanner"
      render={() => <StageBufferLocation1Scanner />}
    />
    <Route exact path="/testing" render={() => <Testing />} />
    <Route
      exact
      path="/stageTestingScanner"
      render={() => <StageTestingScanner />}
    />
    <Route exact path="/bufferlocation2" render={() => <BufferLocation2 />} />
    <Route
      exact
      path="/stageBufferLocation2Scanner"
      render={() => <StageBufferLocation2Scanner />}
    />
    <Route exact path="/posttesting" render={() => <PostTesting />} />
    <Route
      exact
      path="/stagePostTestingScanner"
      render={() => <StagePostTestingScanner />}
    />
    <Route exact path="/packing" render={() => <Packing />} />
    <Route
      exact
      path="/stagePackingScanner"
      render={() => <StagePackingScanner />}
    />
    <Route exact path="/stagePD" render={() => <StagePD />} />
    <Route exact path="/stagePDScanner" render={() => <StagePDScanner />} />
    <Route exact path="/stageBDC" render={() => <StageBDC />} />
    <Route exact path="/stageBDCScanner" render={() => <StageBDCScanner />} />
    <Route exact path="/printing" render={() => <Printing />} />
    <Route
      exact
      path="/stagePrintingScanner"
      render={() => <StagePrintingScanner />}
    />
    <Route
      exact
      path="/stageVisualScanner"
      render={() => <StageVisualScanner />}
    />
    <Route exact path="/visual" render={() => <Visual />} />
    <Route render={() => <Error />} />
  </Switch>
);